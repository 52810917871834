import { useEffect, useState } from "react"
import ReactGA from "react-ga4"

interface eventProps {
  category: string
  action: string
  label: string
  nonInteraction?: boolean
}

const useAnalytics = () => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [hasReferrer, setHasReferrer] = useState(false)
  const [eventQueue, setEventQueue] = useState<eventProps[]>([])

  const isReady = isInitialized && hasReferrer

  const initialize = async (trackingCode: string) => {
    // initialize GA. must specify cookie to be cross domain to work in iframe
    ReactGA.initialize(trackingCode, {
      gaOptions: {
        cookieFlags: "samesite=none;secure",
      },
    })
    setIsInitialized(true)
  }

  const storeEvent = (event: eventProps) => {
    setEventQueue((li) => [...li, event])
  }

  const setReferrer = (referrer: string) => {
    ReactGA.set({
      referrer,
    })
    setHasReferrer(true)
  }

  const clickHandlers = {
    eventRow: (e: React.SyntheticEvent<HTMLElement, Event> | string) =>
      storeEvent({
        category: "artist_find_tickets",
        action: "click_event_row",
        label: typeof e != "string" ? e.currentTarget.dataset.id || "" : "",
      }),
    tickets: (e: React.SyntheticEvent<HTMLElement> | string) =>
      storeEvent({
        category: "artist_find_tickets",
        action:
          typeof e != "string" ? e.currentTarget.dataset.action || "" : "",
        label: typeof e != "string" ? e.currentTarget.dataset.id || "" : "",
      }),
    subscribe: () =>
      storeEvent({
        category: "artist_widget",
        action: "click",
        label: "track_cta",
      }),
    skLogo: () =>
      storeEvent({
        category: "artist_widget",
        action: "click",
        label: "songkick_logo",
      }),
    share: (shareType: React.SyntheticEvent<HTMLElement> | string) =>
      storeEvent({
        category: "artist_find_tickets",
        action: "click",
        label: `share_cta_${shareType}`,
      }),
    countryFilterOption: () =>
      storeEvent({
        category: "artist_widget",
        action: "click",
        label: "country_filter",
      }),
    rsvp: () =>
      storeEvent({
        category: "artist_widget",
        action: "click",
        label: "rsvp",
      }),
    requestAShow: () =>
      storeEvent({
        category: "artist_widget",
        action: "click",
        label: "request_show_cta",
      }),
    remindMe: () =>
      storeEvent({
        category: "artist_widget",
        action: "click",
        label: "remind_me",
      }),
  }

  const viewHandlers = {
    events: (label: string) =>
      storeEvent({
        category: "artist_widget",
        action: "view_events",
        label: label,
        nonInteraction: true,
      }),
    countryFilter: () =>
      storeEvent({
        category: "artist_widget",
        action: "view",
        label: "country_filter",
        nonInteraction: true,
      }),
    subscribeSection: () =>
      storeEvent({
        category: "artist_widget",
        action: "view",
        label: "track_cta",
        nonInteraction: true,
      }),
  }

  const sendEvent = (config: eventProps) => {
    ReactGA.event(config)
  }

  const processEvents = (eventQueue: eventProps[]) => {
    eventQueue.forEach((ev) => sendEvent(ev))
    setEventQueue([])
  }

  useEffect(() => {
    if (isReady && eventQueue.length) {
      processEvents(eventQueue)
    }
  }, [isReady, eventQueue.length])

  return { clickHandlers, viewHandlers, setReferrer, initialize }
}

export default useAnalytics
