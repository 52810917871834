import { useContext, useState, useRef } from "react"

import { PropertiesContext } from "../../../context/PropertiesProvider"
import { PropertyParams } from "../../../types/customTypes"
import { Select, ConfigProvider } from "antd"
import { CheckOutlined, DownOutlined } from "@ant-design/icons"
import { colorThemes } from "../../../styles/theme"
import { DropdownWrap } from "./styles.dropdown"
interface OptionType {
  value: string
  label: string
}

interface DropDownProps {
  value?: string | number
  options?: OptionType[]
  style?: React.CSSProperties
  onChangeDropdown: (selection: string | number) => void
  isDisabled?: boolean
}

const { Option } = Select

const DropDown = ({
  value,
  options,
  onChangeDropdown: _onChangeDropdown,
  style,
  isDisabled,
}: DropDownProps) => {
  const {
    theme: { colorTheme, styleParameters },
  }: PropertyParams = useContext(PropertiesContext)
  const [isOpen, setIsOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = useRef<any>(null)
  const theme = colorThemes[colorTheme].dropdown
  const selectTextColor = styleParameters.color || theme.colorText

  const onChangeDropdown = (selection: string | number) => {
    setTimeout(() => {
      if (selectRef.current) {
        selectRef.current.blur()
      }
    }, 200)

    return _onChangeDropdown(selection)
  }

  return (
    <DropdownWrap color={selectTextColor} themeColor={theme.colorText}>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: theme.colorBgContainer,
            colorPrimaryHover: theme.colorPrimaryHover,
            controlItemBgHover: theme.controlItemBgHover,
            colorBgElevated: theme.colorBgElevated,
            controlItemBgActive: theme.controlItemBgActive,
            colorText: theme.colorText,
            borderRadius: 4,
            fontFamily: "Inter",
            fontWeightStrong: 500,
            fontSize: 14,
            controlHeight: 42,
            colorBorder: "transparent",
          },
        }}
      >
        <Select
          suffixIcon={
            <DownOutlined
              size={24}
              style={{
                color: selectTextColor,
                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                zIndex: 0,
              }}
            />
          }
          ref={selectRef}
          defaultValue="All"
          value={value}
          style={{ ...style, width: 164, opacity: isDisabled ? "0.6" : "1" }}
          onChange={onChangeDropdown}
          onDropdownVisibleChange={setIsOpen}
          placement="bottomLeft"
          disabled={isDisabled}
        >
          {options?.map((item) => (
            <Option key={item.value} value={item.value} label={item.label}>
              <div>
                {item.label}
                {value === item.value && isOpen && (
                  <CheckOutlined
                    size={24}
                    style={{
                      paddingLeft: 12,
                    }}
                  />
                )}
              </div>
            </Option>
          ))}
        </Select>
      </ConfigProvider>
    </DropdownWrap>
  )
}

export default DropDown
