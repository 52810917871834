import { useContext } from "react"
import styled from "styled-components"

import SongkickIcon from "../../assets/icons/SongkickIcon"
import { PropertiesContext } from "../../context/PropertiesProvider"
import { useLinkParams } from "../../hooks"
import { PropertyParams } from "../../types/customTypes"

const Wrap = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
  opacity: 0.8;
`

const SongkickLink = ({ color }: { color: string }) => {
  const { linkWithParams } = useLinkParams()
  const {
    theme: { artistId },
    analytics,
    env: { APP_HOSTS },
  }: PropertyParams = useContext(PropertiesContext)
  const href = linkWithParams({
    href: `https://${APP_HOSTS["skweb"]}`,
    utmParams: {
      source: "11593",
      campaign: "referral",
      medium: artistId,
    },
    withLocale: "subDir",
  })
  return (
    <Wrap>
      <a href={href} target="_blank" onClick={analytics?.clickHandlers?.skLogo}>
        <SongkickIcon color={color} height={20} width={72} />
      </a>
    </Wrap>
  )
}

export default SongkickLink
