const CheckmarkIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.56836 10.9422L1.81501 7.18479C1.39678 6.7661 0.731903 6.7661 0.313673 7.18479C-0.104558 7.60348 -0.104558 8.26908 0.313673 8.68777L4.80697 13.186C5.2252 13.6047 5.9008 13.6047 6.31903 13.186L17.6863 1.817C18.1046 1.39831 18.1046 0.732704 17.6863 0.314016C17.2681 -0.104672 16.6032 -0.104672 16.185 0.314016L5.56836 10.9422Z"
      fill="#03813A"
    />
  </svg>
)

export default CheckmarkIcon
