import styled, { CSSProperties } from "styled-components"

import { font } from "../../styles/mixins"
import { colorThemes } from "../../styles/theme"

interface props {
  colorTheme: "dark" | "light"
  color?: string
  withSpacer?: boolean
  style?: CSSProperties
}

interface sharedProps extends props {
  opacity: number
}

export const Wrap = styled.div`
  margin-bottom: 8px;
`
const typography = font({
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "15px",
  textTransform: "uppercase",
  letterSpacing: "0.05em",
})

const Spacer = (color: string, opacity: number) => `
margin-left: 14px;
position: relative;
&::before {
  content: "";
  height: 3px;
  width: 3px;
  background-color: ${color};
  opacity: ${opacity};
  position: absolute;
  top: 6px;
  left: -8px;
  border-radius: 100%;
}
`

const sharedStyles = ({
  withSpacer,
  colorTheme,
  style,
  opacity,
}: sharedProps) => `
  ${typography}
  color: ${colorThemes[colorTheme].primary};
  margin-bottom: 8px;
  ${
    withSpacer &&
    Spacer(style?.color || colorThemes[colorTheme].primary, opacity)
  }
`

export const Bold = styled.span<props>`
  ${({ withSpacer, colorTheme, style }) =>
    sharedStyles({ withSpacer, colorTheme, style, opacity: 1 })}
`
export const Light = styled.span<props>`
  opacity: 0.5;
  ${({ withSpacer, colorTheme, style }) =>
    sharedStyles({ withSpacer, colorTheme, style, opacity: 0.5 })}
`
