import { rgba } from "polished"
import { RemindMeCTA } from "../components/RemindMe/styles.remindMe"
import {
  WHITE,
  ASH,
  BLACK,
  CHARCOAL,
  OFF_BLACK,
  TRANSITION_TIME,
} from "./variables"

const shared = ({
  bg,
  text,
  outline,
  hoverBg,
  activeBg,
  focusBg,
  hoverOutline,
  focusOutline,
  activeOutline,
  borderRadius,
}: {
  bg: string
  hoverBg: string
  activeBg: string
  focusBg: string
  text?: string
  outline?: string
  hoverOutline?: string
  focusOutline?: string
  activeOutline?: string
  borderRadius?: string
}) => `
    ${text ? `color: ${text};` : ""}
    background-color: ${bg};
    ${outline ? `outline: 2px solid ${outline};` : ""}
    ${
      borderRadius
        ? `
        border-radius: ${borderRadius};
        -webkit-border-radius: ${borderRadius};`
        : ""
    }
    &:hover {
      background: ${hoverBg};
      ${hoverOutline ? `outline: 2px solid ${hoverOutline};` : ""}
    }
    &:focus {
      background: ${focusBg};
      ${focusOutline ? `outline: 2px solid ${focusOutline};` : ""}
    }
    &:active {
      background-color: ${activeBg};
      ${activeOutline ? `outline: 2px solid ${activeOutline};` : ""}
    }
  `

const buttons = {
  dark: {
    primary: ({ bgColor = WHITE, textColor = BLACK }) => `
      display: inline-block;
      ${shared({
        borderRadius: "4px",
        text: textColor,
        bg: bgColor,
        hoverBg: rgba(bgColor, 0.8),
        focusBg: rgba(bgColor, 0.6),
        activeBg: rgba(bgColor, 0.4),
      })}
      transition: background-color ${TRANSITION_TIME}, outline ${TRANSITION_TIME};
    `,
    secondary: ({ bgColor = WHITE, textColor = BLACK }) => `
    ${shared({
      borderRadius: "4px",
      text: textColor,
      bg: rgba(bgColor, 0.8),
      hoverBg: rgba(bgColor, 0.6),
      focusBg: rgba(bgColor, 0.5),
      activeBg: rgba(bgColor, 0.3),
    })}
      transition: background-color ${TRANSITION_TIME}, outline ${TRANSITION_TIME};
    `,
    tertiary: ({ bgColor = WHITE, textColor = BLACK }) => `
      ${shared({
        borderRadius: "4px",
        text: textColor,
        bg: rgba(bgColor, 0.23),
        hoverBg: rgba(bgColor, 0.3),
        focusBg: rgba(bgColor, 0.2),
        activeBg: rgba(bgColor, 0.3),
        outline: rgba(WHITE, 0.2),
        hoverOutline: rgba(WHITE, 0.2),
        focusOutline: rgba(WHITE, 0.8),
        activeOutline: rgba(WHITE, 0.8),
      })}
      transition: background-color ${TRANSITION_TIME}, outline ${TRANSITION_TIME};
    `,
    framedIcon: () => `
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      width: 28px;
      ${shared({
        borderRadius: "4px",
        bg: "transparent",
        hoverBg: rgba(WHITE, 0.15),
        focusBg: rgba(WHITE, 0.1),
        activeBg: rgba(WHITE, 0.4),
      })}
      transition: background-color ${TRANSITION_TIME}, outline ${TRANSITION_TIME};
    `,
    icon: () => `
      height: 36px;
      width: 36px;
      ${shared({
        borderRadius: "4px",
        bg: "transparent",
        hoverBg: rgba(WHITE, 0.1),
        focusBg: rgba(WHITE, 0.2),
        activeBg: rgba(WHITE, 0.1),
        outline: "transparent",
        hoverOutline: rgba(WHITE, 0.1),
        focusOutline: rgba(WHITE, 0.5),
        activeOutline: rgba(WHITE, 0.3),
      })}
      `,
    link: () => `
      line-height: 17px;
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      ${shared({
        borderRadius: "4px",
        text: WHITE,
        bg: rgba(WHITE, 0.2),
        hoverBg: rgba(BLACK, 0.03),
        focusBg: rgba(WHITE, 0.2),
        activeBg: rgba(WHITE, 0.2),
        outline: rgba(WHITE, 0.2),
        hoverOutline: rgba(BLACK, 0.03),
        focusOutline: rgba(WHITE, 0.8),
        activeOutline: WHITE,
      })}
      transition: background-color ${TRANSITION_TIME}, outline ${TRANSITION_TIME};
    `,
    ctaFlag: ({ bgColor = WHITE, textColor = BLACK }) => `
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 10px 10px 10px;
      margin-bottom: 12px;
      ${shared({
        borderRadius: "4px",
        text: WHITE,
        bg: rgba(CHARCOAL, 0.2),
        hoverBg: rgba(CHARCOAL, 0.35),
        focusBg: rgba(CHARCOAL, 0.2),
        activeBg: rgba(CHARCOAL, 0.35),
      })}
      &:hover {
        ${RemindMeCTA} {
          background: ${rgba(bgColor, 0.7)};
        }
      }
      &:focus {
        ${RemindMeCTA} {
          background:  ${rgba(bgColor, 0.5)};
        }
      }
      &:active {
        ${RemindMeCTA} {
          background: ${rgba(bgColor, 0.7)};
        }
      }
      ${RemindMeCTA} {
        color: ${textColor};
        background: ${rgba(bgColor, 0.9)};
      }
      transition: background-color ${TRANSITION_TIME}, outline ${TRANSITION_TIME};
    `,
  },
  light: {
    primary: ({ bgColor = BLACK, textColor = WHITE }) => `
      display: inline-block;
      ${shared({
        borderRadius: "4px",
        text: textColor,
        bg: bgColor,
        hoverBg: rgba(bgColor, 0.8),
        focusBg: rgba(bgColor, 0.6),
        activeBg: rgba(bgColor, 0.4),
      })}
      transition: background-color ${TRANSITION_TIME}, outline ${TRANSITION_TIME};
    `,
    secondary: ({ bgColor = BLACK, textColor = WHITE }) => `
    ${shared({
      borderRadius: "4px",
      text: textColor,
      bg: rgba(bgColor, 0.8),
      hoverBg: rgba(bgColor, 0.6),
      focusBg: rgba(bgColor, 0.5),
      activeBg: rgba(bgColor, 0.3),
    })}
      transition: background-color ${TRANSITION_TIME}, outline ${TRANSITION_TIME};
    `,
    tertiary: ({ bgColor = WHITE, textColor = CHARCOAL }) => `
      ${shared({
        borderRadius: "4px",
        text: textColor,
        bg: rgba(bgColor, 0.5),
        hoverBg: rgba(bgColor, 0.8),
        focusBg: rgba(bgColor, 0.05),
        activeBg: rgba(bgColor, 0.5),
        outline: rgba(BLACK, 0.2),
        hoverOutline: rgba(BLACK, 0.2),
        focusOutline: rgba(BLACK, 0.8),
        activeOutline: rgba(BLACK, 0.8),
      })}
      transition: background-color ${TRANSITION_TIME}, outline ${TRANSITION_TIME};
    `,
    framedIcon: () => `
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      width: 28px;
      ${shared({
        borderRadius: "4px",
        bg: "transparent",
        hoverBg: rgba(BLACK, 0.15),
        focusBg: rgba(BLACK, 0.1),
        activeBg: rgba(BLACK, 0.4),
      })}
      transition: background-color ${TRANSITION_TIME}, outline ${TRANSITION_TIME};
  `,
    icon: () => `
      height: 36px;
      width: 36px;
      ${shared({
        borderRadius: "4px",
        bg: "transparent",
        hoverBg: rgba(BLACK, 0.05),
        focusBg: rgba(BLACK, 0.1),
        activeBg: rgba(BLACK, 0.05),
        outline: "transparent",
        hoverOutline: rgba(BLACK, 0.05),
        focusOutline: rgba(BLACK, 0.55),
        activeOutline: rgba(BLACK, 0.2),
      })}
      `,
    link: () => `
      line-height: 17px;
      padding: 0 10px;
      height: 36px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      ${shared({
        borderRadius: "4px",
        text: BLACK,
        bg: rgba(CHARCOAL, 0.03),
        hoverBg: rgba(CHARCOAL, 0.1),
        focusBg: rgba(CHARCOAL, 0.03),
        activeBg: rgba(CHARCOAL, 0.1),
        outline: rgba(CHARCOAL, 0.03),
        hoverOutline: rgba(CHARCOAL, 0.1),
        focusOutline: rgba(BLACK, 0.8),
        activeOutline: rgba(BLACK, 0.8),
      })}
      transition: background-color ${TRANSITION_TIME}, outline ${TRANSITION_TIME};
    `,
    ctaFlag: ({ bgColor = BLACK, textColor = WHITE }) => `
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 10px 10px 10px;
      margin-bottom: 12px;
      ${shared({
        borderRadius: "4px",
        text: CHARCOAL,
        bg: rgba(CHARCOAL, 0.05),
        hoverBg: rgba(CHARCOAL, 0.12),
        focusBg: rgba(CHARCOAL, 0.05),
        activeBg: rgba(CHARCOAL, 0.12),
      })}
      &:hover {
        ${RemindMeCTA} {
          background: ${rgba(bgColor, 0.7)};
        }
      }
      &:focus {
        ${RemindMeCTA} {
          background:  ${rgba(bgColor, 0.5)};
        }
      }
      &:active {
        ${RemindMeCTA} {
          background: ${rgba(bgColor, 0.7)};
        }
      }
      ${RemindMeCTA} {
        color: ${textColor};
        background: ${rgba(bgColor, 0.9)};
      }
      transition: background-color ${TRANSITION_TIME}, outline ${TRANSITION_TIME};
    `,
  },
}

const inputs = {
  dark: {
    dropdown: {
      colorBgContainer: `${rgba(WHITE, 0.1)}`,
      colorPrimaryHover: `${rgba(WHITE, 0.4)}`,
      controlItemBgHover: `${OFF_BLACK}`,
      colorBgElevated: `${CHARCOAL}`,
      controlItemBgActive: `${CHARCOAL}`,
      colorText: `${WHITE}`,
      colorTextPlaceholder: `${WHITE}`,
    },
  },
  light: {
    dropdown: {
      colorBgContainer: `${rgba(CHARCOAL, 0.03)}`,
      colorPrimaryHover: `${rgba(CHARCOAL, 0.15)}`,
      controlItemBgHover: ASH,
      colorBgElevated: `${WHITE}`,
      controlItemBgActive: `${WHITE}`,
      colorText: `${rgba(CHARCOAL, 0.8)}`,
      colorTextPlaceholder: `${rgba(CHARCOAL, 0.8)}`,
    },
  },
}

const cards = {
  dark: {
    primary: rgba(WHITE, 0.1),
    primaryHover: rgba(WHITE, 0.15),
    cta: rgba(WHITE, 0.1),
    ctaHover: "",
    info: rgba(WHITE, 0.1),
    infoHover: "",
  },
  light: {
    primary: rgba(CHARCOAL, 0.03),
    primaryHover: rgba(CHARCOAL, 0.05),
    cta: rgba(CHARCOAL, 0.03),
    ctaHover: "",
    info: rgba(CHARCOAL, 0.03),
    infoHover: "",
  },
}

export const colorThemes = {
  dark: {
    primary: `${WHITE}`,
    shareBg: `${OFF_BLACK}`,
    buttons: {
      ...buttons["dark"],
    },
    cards: {
      ...cards["dark"],
    },
    ...inputs["dark"],
  },
  light: {
    primary: `${BLACK}`,
    shareBg: `${WHITE}`,
    buttons: {
      ...buttons["light"],
    },
    cards: {
      ...cards["light"],
    },
    ...inputs["light"],
  },
}
