import { useRef, useContext, SyntheticEvent } from "react"
import moment from "moment"
import { atcb_action } from "add-to-calendar-button"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { PropertyParams } from "../../types/customTypes"
import { SaleDate, RemindMeCTA } from "./styles.remindMe"
import { Performance } from "../../types/customTypes"
import { Button } from ".."
import { useTranslation } from "react-i18next"

interface configType {
  name: string
  description: string
  dates: [
    {
      startDate: string
      startTime: string
      endTime: string
      timeZone: string
    }
  ]
  options: "Google"[]
}

const RemindMe = ({
  performance,
  skCalendarLink,
}: {
  skCalendarLink: string
  performance: Performance
}) => {
  const {
    theme: {
      colorTheme,
      language,
      showRemindMe,
      styleParameters,
      buttonTextColor,
      buttonBgColor,
    },
    analytics,
  }: PropertyParams = useContext(PropertiesContext)
  const { t } = useTranslation()

  const {
    event: { ticketOnSaleDate },
    artist: { displayName: artistName },
  } = performance

  if (!ticketOnSaleDate || !showRemindMe) return null

  const butonRef = useRef(null)
  const timeProvided = ticketOnSaleDate.includes("T")
  const startDate = moment(ticketOnSaleDate)
    .locale(language)
    .format("YYYY-MM-DD")
  const startTime = timeProvided
    ? moment(ticketOnSaleDate).locale(language).format("HH:mm")
    : "09:30"
  const endTime = timeProvided
    ? moment(ticketOnSaleDate)
        .locale(language)
        .add(30, "minutes")
        .format("HH:mm")
    : "10:00"

  const config: configType = {
    name: t("artistTicketsOnSale").replace("__ARTIST_NAME__", artistName),
    description: t("remindMeCalendar").replace("__EVENT_URL__", skCalendarLink),
    dates: [
      {
        startDate,
        startTime,
        endTime,
        timeZone: "currentBrowser",
      },
    ],
    options: ["Google"],
  }

  const handleClick = (event: SyntheticEvent<HTMLButtonElement, Event>) => {
    if (!butonRef.current) return undefined
    else {
      analytics?.clickHandlers?.remindMe(event)
      atcb_action(config, butonRef.current)
    }
  }

  const displayDate = moment(ticketOnSaleDate)
    .locale(language)
    .format("ddd D MMM YYYY")

  // remove theme color from buttons

  return (
    <Button
      style={styleParameters}
      styleType="ctaFlag"
      ref={butonRef}
      onClick={handleClick}
    >
      <SaleDate>
        {t("onSale")} {displayDate}
      </SaleDate>
      <RemindMeCTA
        colorTheme={colorTheme}
        style={{
          color: buttonTextColor,
          background: buttonBgColor,
        }}
      >
        {t("setAReminder")}
      </RemindMeCTA>
    </Button>
  )
}

export default RemindMe
