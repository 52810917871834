import { forwardRef, ForwardedRef, useContext } from "react"

import { PropertiesContext } from "../../../context/PropertiesProvider"
import { PropertyParams } from "../../../types/customTypes"
import { Btn } from "./styles.button"

export interface props {
  styleType:
    | "primary"
    | "secondary"
    | "tertiary"
    | "framedIcon"
    | "icon"
    | "link"
    | "ctaFlag"
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
  children?: React.ReactNode
  style?: React.CSSProperties
  withIcon?: boolean | undefined
  value?: string
}

const Button = forwardRef(
  (props: props, ref: ForwardedRef<HTMLButtonElement>) => {
    const { children, onClick, withIcon, style, ...otherProps } = props
    const {
      theme: { colorTheme, buttonBgColor },
    }: PropertyParams = useContext(PropertiesContext)
    return (
      <Btn
        ref={ref}
        colorTheme={colorTheme}
        onClick={onClick}
        withIcon={withIcon}
        style={style}
        {...otherProps}
        bgColor={
          otherProps.styleType === "framedIcon" ? buttonBgColor : undefined
        }
      >
        {children}
      </Btn>
    )
  }
)

export default Button
