import { useState, createContext } from "react"
import { useParams, useConnect, useAnalytics, useEnv } from "../hooks"
import { PropertyParams } from "../types/customTypes"

export const PropertiesContext = createContext<PropertyParams>(
  {} as PropertyParams
)

export interface SearchParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styleParameters: any
  artistId: string
  header: string
  language: string
  isStaging: boolean
  theme: string
  showOtherArtists: boolean
  showTrackButton: boolean
  color: string
  fontFamily: string
  itemsPerPage: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PropertiesProviderProps {}

export function PropertiesProvider(props: PropertiesProviderProps) {
  const searchParams = new URLSearchParams(window.location.search)
  // responsive
  const [isMobile, setIsMobile] = useState(false)
  // calendar
  const [isPast, setIsPast] = useState(false)
  const [isOnTour, setIsOnTour] = useState(true)

  // hooks
  const theme: PropertyParams["theme"] = useParams({ searchParams })
  const { clickHandlers, viewHandlers, setReferrer, initialize } =
    useAnalytics()
  const parentMethods = useConnect({ methods: { setReferrer } })
  const { env } = useEnv({ methods: { initAnalytics: initialize } })

  const responsive = {
    isMobile,
    setIsMobile,
  }

  const analytics = {
    clickHandlers,
    viewHandlers,
  }

  const calendar = {
    isPast,
    setIsPast,
    isOnTour,
    setIsOnTour,
  }

  return (
    <PropertiesContext.Provider
      value={{
        theme,
        parentMethods,
        responsive,
        analytics,
        calendar,
        env,
      }}
      {...props}
    ></PropertiesContext.Provider>
  )
}
