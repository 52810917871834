import moment from "moment"
import { useEffect, useState } from "react"
import { Performance } from "../../types/customTypes"
import "moment/locale/fr"
import "moment/locale/es"
import "moment/locale/de"
import "moment/locale/pt"

const useDateDisplay = ({
  performance,
  isMobile,
  isPast,
  language,
}: {
  performance: Performance
  isMobile: boolean
  isPast: boolean
  language: string
}) => {
  const [dayFormat, setDayFormat] = useState("ddd")
  const [monthFormat, setMonthFormat] = useState("MMM")

  const { start, type, end } = performance.event
  const { date: performanceDate } = performance

  // date vars
  const now = moment()
  const [startYear, startMonth] = start.date.split("-")
  const hasEndDate = !!end?.date && start.date !== end?.date
  const isCrossMonth = !!end?.date && end.date.split("-")[1] !== startMonth
  const isCrossYear = !!end?.date && end.date.split("-")[0] !== startYear
  const year = (eventYear: string) => {
    const thisYear = now.year().toString()
    const isCurrentYear = thisYear === eventYear
    if (isPast) return " YYYY"
    return isCurrentYear ? "" : " YYYY"
  }

  // Callbacks

  const formatTime = (
    datetime: string,
    config: string,
    language: string,
    fulltime?: string | null
  ) => {
    // config and format must be set for time strings
    const time = moment(datetime, config).locale(language).format(config)
    if (type === "LiveStreamConcert" && fulltime) {
      const fullOffset = fulltime.slice(-5).split("")
      fullOffset.splice(3, 0, ":")

      return `${time} ${fullOffset.join("")}`
    }
    return time
  }
  const formatDate = (datetime: string, config: string, language: string) => {
    const date = moment(datetime)
      .locale(language)
      .format(config)
      .toUpperCase()
      .replaceAll(".", "")

    return date
  }
  const dayMonthYear = (date: string) =>
    `${dayFormat} D ${monthFormat}${year(date.split("-")[0])}`

  const eventDates = () => {
    const dayMonth = `${dayFormat} D ${monthFormat}`
    const day = `${dayFormat} D`
    if (performanceDate)
      return formatDate(
        performanceDate,
        dayMonthYear(performanceDate),
        language
      )
    if (!hasEndDate)
      return formatDate(start.date, dayMonthYear(start.date), language)
    if (!isCrossMonth)
      return `${formatDate(start.date, day, language)} - ${formatDate(
        end.date,
        dayMonthYear(end.date),
        language
      )}`
    if (isCrossYear)
      return `${formatDate(
        start.date,
        dayMonthYear(start.date),
        language
      )} - ${formatDate(end.date, dayMonthYear(end.date), language)}`
    // cross month
    return `${formatDate(start.date, dayMonth, language)} - ${formatDate(
      end.date,
      dayMonthYear(end.date),
      language
    )}`
  }
  const eventTime = () =>
    formatTime(start.time || "", "h:mm A", language, start.datetime)

  // alternate date formatting for mob / dt
  useEffect(() => {
    if (isMobile) {
      setDayFormat("ddd")
      setMonthFormat("MMM")
    } else {
      setDayFormat("dddd")
      setMonthFormat("MMMM")
    }
  }, [isMobile])

  return {
    dayFormat,
    dayMonthYear,
    eventDates,
    eventTime,
    formatDate,
    formatTime,
    hasEndDate,
    isCrossMonth,
    isCrossYear,
    monthFormat,
    setDayFormat,
    setMonthFormat,
    start,
    startMonth,
    startYear,
    year,
  }
}

export default useDateDisplay
