import { useContext } from "react"

import { LinkButton } from ".."
import HeartIcon from "../../assets/icons/HeartIcon"
import { PropertyParams } from "../../types/customTypes"
import { PropertiesContext } from "../../context/PropertiesProvider"
import { colorThemes } from "../../styles/theme"
import { useLinkParams } from "../../hooks"

interface props {
  eventLink: string
  eventId: number
}

const RSVP = ({ eventLink, eventId }: props) => {
  const {
    theme: { colorTheme, styleParameters, artistId, showRSVP, showShareButton },
    analytics,
    env: { APP_HOSTS },
  }: PropertyParams = useContext(PropertiesContext)

  if (!showRSVP) return null

  const { linkWithParams } = useLinkParams()

  const href = linkWithParams({
    href: `https://${APP_HOSTS["accounts"]}/signup/new`,
    utmParams: {
      campaign: "rsvp",
      medium: "partner",
      content: artistId,
      source: "11593",
    },
    customParams: {
      source_product: "skweb",
      event_id: `${eventId}`,
      attendance_type: "tracking",
      login_success_url: eventLink,
    },
    withLocale: "param",
  })

  return (
    <LinkButton
      href={href}
      styleType="framedIcon"
      onClick={analytics?.clickHandlers.rsvp}
      style={{ marginTop: showShareButton ? "12px" : "" }}
    >
      <HeartIcon
        color={styleParameters.color || colorThemes[colorTheme].primary}
      />
    </LinkButton>
  )
}

export default RSVP
