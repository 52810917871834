import styled from "styled-components"

import { font, responsive } from "../../styles/mixins"
import { colorThemes } from "../../styles/theme"

interface props {
  colorTheme: "dark" | "light"
}

export const Wrap = styled.div<props>`
  margin-bottom: 4px;
  ${responsive.tablet(`
    margin-bottom: 8px;
  `)}
  color: ${({ colorTheme }) => colorThemes[colorTheme].primary};
`

export const City = styled.p`
  ${font({
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "27px",
    textTransform: "sentencecase",
  })}
  padding-bottom: 2px;
`
export const Venue = styled.p`
  ${font({ fontSize: "14px", fontWeight: 400, lineHeight: "21px" })}
`
