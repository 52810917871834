import { useContext } from "react"
import { NoEventsMessage, PerformanceItem, LoadingSpinner } from ".."
import { PropertiesContext } from "../../context/PropertiesProvider"
import { Performance } from "../../types/customTypes"
import { Wrap } from "./styles.performanceItemList"

interface PerformanceItemListProps {
  calendar: Performance[]
  isRequesting: boolean
  calendarError: string
}

export const PerformanceItemList = ({
  calendar,
  isRequesting,
  calendarError,
}: PerformanceItemListProps) => {
  const {
    theme: { showPastEventsIfOffTour },
    calendar: { isOnTour, isPast },
  } = useContext(PropertiesContext)
  if (isRequesting) return <LoadingSpinner />
  if (calendar.length)
    return (
      <>
        {isPast && !isOnTour && showPastEventsIfOffTour && (
          <NoEventsMessage calendarError="" />
        )}
        <Wrap>
          {calendar.map((performance: Performance) => (
            <PerformanceItem key={performance.id} performance={performance} />
          ))}
        </Wrap>
      </>
    )

  return <NoEventsMessage calendarError={calendarError} />
}
