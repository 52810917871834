import styled from "styled-components"

import { responsive } from "../../styles/mixins"
import fallbackImg from "../../assets/images/fallback-image.png"
import { useMemo, useState } from "react"

interface styleProps {
  fallbackImage: string
}

const Image = styled.img<styleProps>`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 16px;
  background-image: url(${({ fallbackImage }) => fallbackImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  ${responsive.tablet(`
    margin-right: 12px;
`)}
  /* fade in on mount */
  animation: fadein .3s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
const Spacer = styled.div`
  height: 35px;
  width: 35px;
  margin-right: 16px;
`

const ArtistImage = ({ artistId }: { artistId: string }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const mediaPath = `https://images.sk-static.com/images/media/profile_images/artists/${artistId}/large_avatar`

  useMemo(() => {
    const img = new window.Image()
    img.src = mediaPath
    img.onload = () => {
      setIsLoaded(true)
    }
  }, [artistId])

  if (!isLoaded) return <Spacer />
  return <Image src={mediaPath} fallbackImage={fallbackImg} />
}

export default ArtistImage
