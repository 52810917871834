import { useContext, useState, useEffect } from "react"
import styled from "styled-components"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { TourboxEvent, PropertyParams } from "../../types/customTypes"
import { font } from "../../styles/mixins"
import { colorThemes } from "../../styles/theme"
import { useTranslation } from "react-i18next"

interface props {
  colorTheme: "dark" | "light"
}
const ADDITIONAL_ARTISTS_LIMIT = 3
const ArtistList = styled.p<props>`
  ${font({
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
  })}
  color: ${({ colorTheme }) => colorThemes[colorTheme].primary};
  opacity: 0.7;
`

const AdditionalArtists = ({ event }: { event: TourboxEvent }) => {
  const [artists, setArtists] = useState<string[]>([])
  const {
    theme: { artistId, colorTheme, styleParameters, showOtherArtists },
  }: PropertyParams = useContext(PropertiesContext)
  const { t } = useTranslation()

  const { performance } = event

  const findAdditionalArtists = () => {
    const artists = performance
      .slice(0, 10) // dont loop over the whole lineup for large events
      .reduce((artists, { artist }) => {
        if (artist.id.toString() === artistId) return artists
        return [...artists, artist.displayName]
      }, [] as string[])
    setArtists(artists)
  }

  const renderAdditionalArtists = () => {
    const withString = t("with")
    const andString = t("and")
    const andMoreString = t("andMore")
    if (artists.length === 1) return `${withString} ${artists}`
    if (artists.length <= ADDITIONAL_ARTISTS_LIMIT) {
      return `${withString} ${artists
        .slice(0, -1)
        .join(", ")} ${andString} ${artists.slice(-1)}`
    }
    return `${withString} ${artists
      .slice(0, ADDITIONAL_ARTISTS_LIMIT)
      .join(", ")} ${andMoreString}`
  }

  useEffect(() => {
    findAdditionalArtists()
  }, [])

  if (!showOtherArtists || !artists.length) return null

  return (
    <ArtistList colorTheme={colorTheme} style={styleParameters}>
      {renderAdditionalArtists()}
    </ArtistList>
  )
}

export default AdditionalArtists
