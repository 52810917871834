const PaperClipIcon = ({
  color,
  style,
}: {
  color: string
  style?: React.CSSProperties
}) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M17.5421 10.2026L9.33802 18.4757C9.0197 18.8 8.63992 19.0577 8.22088 19.2335C7.80183 19.4094 7.35194 19.5 6.89748 19.5C6.44302 19.5 5.99313 19.4094 5.57409 19.2335C5.15504 19.0577 4.77526 18.8 4.45694 18.4757L2.00262 15.98C1.36012 15.3274 1 14.4483 1 13.5325C1 12.6167 1.36012 11.7377 2.00262 11.0851L10.7444 2.31571C11.0008 2.05724 11.3058 1.85209 11.6418 1.71208C11.9779 1.57208 12.3383 1.5 12.7024 1.5C13.0664 1.5 13.4269 1.57208 13.7629 1.71208C14.099 1.85209 14.404 2.05724 14.6603 2.31571L15.6393 3.29469C15.8978 3.55105 16.1029 3.85605 16.2429 4.1921C16.3829 4.52814 16.455 4.88859 16.455 5.25263C16.455 5.61668 16.3829 5.97712 16.2429 6.31317C16.1029 6.64921 15.8978 6.95422 15.6393 7.21058L7.89024 14.9872C7.76206 15.1164 7.60956 15.219 7.44154 15.289C7.27351 15.359 7.09329 15.3951 6.91127 15.3951C6.72925 15.3951 6.54902 15.359 6.381 15.289C6.21298 15.219 6.06048 15.1164 5.9323 14.9872L5.4497 14.4908C5.32047 14.3626 5.21789 14.2101 5.14789 14.0421C5.07789 13.8741 5.04185 13.6939 5.04185 13.5119C5.04185 13.3298 5.07789 13.1496 5.14789 12.9816C5.21789 12.8136 5.32047 12.6611 5.4497 12.5329L10.6479 7.37604"
      stroke={color}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PaperClipIcon
