const FacebookIcon = ({ color }: { color?: string }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.35 10.0611C19.35 14.4937 16.306 18.2075 12.2125 19.2072V13.6194H13.8926H14.451L14.5352 13.0674L14.9785 10.1591L15.0925 9.41111H14.3359H12.2125V8.17381C12.2125 7.86961 12.2877 7.64929 12.4044 7.51352C12.5054 7.39602 12.7135 7.25261 13.1921 7.25261H14.4531H15.1031V6.60261V4.12663V3.57873L14.5631 3.486L14.4531 4.12663C14.5631 3.486 14.5631 3.48599 14.563 3.48598L14.5629 3.48596L14.5625 3.4859L14.5615 3.48572L14.5582 3.48516L14.5467 3.48324L14.505 3.47642C14.4691 3.47066 14.4175 3.46257 14.3525 3.45294C14.2225 3.43369 14.0384 3.40819 13.8192 3.38272C13.3835 3.3321 12.7965 3.28012 12.2146 3.28012C10.9401 3.28012 9.81502 3.67128 9.00826 4.48373C8.20133 5.29635 7.7875 6.45514 7.7875 7.84452V9.41111H5.89844H5.24844V10.0611V12.9694V13.6194H5.89844H7.7875V19.2072C3.694 18.2075 0.65 14.4937 0.65 10.0611C0.65 4.85976 4.83986 0.65 10 0.65C15.1601 0.65 19.35 4.85976 19.35 10.0611Z"
      stroke={color}
      strokeWidth="1.3"
    />
  </svg>
)

export default FacebookIcon
