import { useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { colorThemes } from "../../styles/theme"
import { PropertyParams } from "../../types/customTypes"
import { font } from "../../styles/mixins"
import SongkickLink from "../SongkickLink/SongkickLink"

interface props {
  colorTheme: "dark" | "light"
}

const Wrap = styled.header<props>`
  color: ${({ colorTheme }) => colorThemes[colorTheme].primary};
  ${font({
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  })}
  padding: 18px 0 0 0;
  margin: 0px 16px 16px;
  display: flex;
  justify-content: space-between;
`

const Header = () => {
  const {
    theme: { header, colorTheme, styleParameters },
  }: PropertyParams = useContext(PropertiesContext)
  const { t } = useTranslation()

  return (
    <Wrap colorTheme={colorTheme} style={styleParameters} data-testid="header">
      <p>{header || t("defaultHeader")}</p>
      <SongkickLink
        color={styleParameters?.color || colorThemes[colorTheme].primary}
      />
    </Wrap>
  )
}

export default Header
