import { useContext, useState } from "react"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { Performance, PropertyParams } from "../../types/customTypes"
import { useTranslation } from "react-i18next"
import { useLinkParams } from "../../hooks"
import { Grid, PerformanceLink } from "./styles.performanceItem"
import {
  DateDisplay,
  Location,
  TicketLinks,
  AdditionalArtists,
  EventName,
  LiveStreamLinks,
  EventIcons,
  Card,
} from ".."

const PerformanceItem = ({ performance }: { performance: Performance }) => {
  const [overlay, setOverlay] = useState(false)
  const {
    theme: { artistId },
    analytics,
    calendar: { isPast },
  }: PropertyParams = useContext(PropertiesContext)
  const { linkWithParams } = useLinkParams()

  const { event } = performance
  const { t } = useTranslation()

  const isLiveStream = event?.type === "LiveStreamConcert"
  const liveStreamText = t("liveStream")
  const liveStreamEventLabel = t("liveStreamTicketLabel")

  const href = linkWithParams({
    href: performance.event.uri,
    utmParams: {
      campaign: "event-row-click",
      content: artistId,
    },
    withLocale: "subDir",
  })

  const toggleOverlay = (isActive: boolean) => {
    setOverlay(isActive)
  }

  const renderLinks = () => {
    if (isPast) return null
    if (isLiveStream)
      return (
        <LiveStreamLinks
          label={liveStreamEventLabel}
          link={performance.event.uri}
        />
      )
    return <TicketLinks performance={performance} />
  }

  return (
    <Card overlayActive={overlay} styleType="primary">
      <Grid>
        <PerformanceLink
          data-id={event.id}
          href={href}
          target="_blank"
          onClick={analytics?.clickHandlers?.eventRow}
          mobileMarginBottom={isPast ? "0px" : "16px"}
        >
          <DateDisplay performance={performance} />
          {isLiveStream ? (
            <EventName
              displayName={liveStreamText}
              style={{ marginBottom: "24px" }}
            />
          ) : (
            <Location event={event} />
          )}
          <AdditionalArtists event={event} />
        </PerformanceLink>
        <EventIcons
          toggleOverlay={toggleOverlay}
          eventLink={href}
          eventId={event.id}
        />
        {renderLinks()}
      </Grid>
    </Card>
  )
}

export default PerformanceItem
