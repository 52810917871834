import { useContext } from "react"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { Performance, PropertyParams } from "../../types/customTypes"
import { Wrap, Bold, Light } from "./styles.dateDisplay"
import { useTranslation } from "react-i18next"
import useDateDisplay from "../../hooks/UseDateDisplay/UseDateDisplay"

const DateDisplay = ({ performance }: { performance: Performance }) => {
  const {
    theme: { colorTheme, styleParameters, language },
    responsive: { isMobile },
    calendar: { isPast },
  }: PropertyParams = useContext(PropertiesContext)

  const { eventDates, eventTime, start } = useDateDisplay({
    performance,
    isMobile,
    isPast,
    language,
  })
  const { t } = useTranslation()

  const renderHelper = (
    Comp: typeof Bold | typeof Light,
    content: string,
    withSpacer?: boolean
  ) => {
    return (
      <Comp
        style={styleParameters}
        colorTheme={colorTheme}
        withSpacer={withSpacer}
      >
        {content}
      </Comp>
    )
  }

  return (
    <Wrap>
      {isPast ? (
        <>
          {renderHelper(Light, t("pastEvent"))}
          {start.date && renderHelper(Bold, eventDates(), true)}
        </>
      ) : (
        <>
          {start.date && renderHelper(Bold, eventDates())}
          {start.time && renderHelper(Light, eventTime(), true)}
        </>
      )}
    </Wrap>
  )
}

export default DateDisplay
