import Router from "../routes/Routes"
import * as Sentry from "@sentry/react"

import "./App.css"
import "../i18n/config"

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Router />
    </Sentry.ErrorBoundary>
  )
}

export default App
