import { useContext } from "react"
import styled from "styled-components"
import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { colorThemes } from "../../styles/theme"
import { PropertyParams } from "../../types/customTypes"

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding 24px;
`

const LoadingSpinner = () => {
  const {
    theme: { colorTheme, styleParameters },
  }: PropertyParams = useContext(PropertiesContext)

  const Icon = (
    <LoadingOutlined
      style={{
        fontSize: 32,
        color: styleParameters.color || colorThemes[colorTheme].primary,
      }}
      spin
    />
  )
  return (
    <Wrap>
      <Spin indicator={Icon} />
    </Wrap>
  )
}

export default LoadingSpinner
