import { useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { PropertyParams, CalendarResArtist } from "../../types/customTypes"
import { LinkButton, Card } from ".."
import { useLinkParams } from "../../hooks"
import { font, responsive } from "../../styles/mixins"
import { colorThemes } from "../../styles/theme"
import { skwebPathLocale } from "../../helpers/Helpers"

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  ${responsive.tablet(`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `)}
`

interface copyProps {
  colorTheme: "light" | "dark"
}

const CopyWrap = styled.div<copyProps>`
  color: ${({ colorTheme }) => colorThemes[colorTheme].primary};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  ${font({
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
  })}
  ${responsive.tablet(`
    padding-right: 16px;
    margin-bottom: 0;
  `)}
`

interface props {
  hasEvents: boolean
  artist: CalendarResArtist
}

const RequestAShow = ({ hasEvents, artist }: props) => {
  const { t } = useTranslation()
  const {
    theme: {
      colorTheme,
      styleParameters,
      showRequestEvent,
      language,
      buttonBgColor,
      buttonTextColor,
    },
    env: { APP_HOSTS },
    analytics,
  }: PropertyParams = useContext(PropertiesContext)

  if (!showRequestEvent) return null

  const { linkWithParams } = useLinkParams()

  const url = linkWithParams({
    href: `https://${APP_HOSTS["accounts"]}/artists/${artist?.id}/trackings/signup`,
    utmParams: {
      campaign: "request-show-cta",
      source: "11593",
      medium: "partner",
      content: `${artist?.id}`,
    },
    customParams: {
      source_product: "widget",
      success_url: `https://${APP_HOSTS["skweb"]}${skwebPathLocale(
        language,
        `/artists/${artist.id}/location-show-request/new`
      )}`,
    },
    withLocale: "param",
  })

  const copy = () => {
    const copy = hasEvents ? t("requestAShow") : t("noEventsRequestAShow")
    return <span>{copy.replace(/__ARTIST_NAME__/, artist?.name)}</span>
  }

  return (
    <Card styleType="cta">
      <Inner>
        <CopyWrap colorTheme={colorTheme} style={styleParameters}>
          {copy()}
        </CopyWrap>
        <div>
          <LinkButton
            styleType="primary"
            href={url}
            onClick={analytics?.clickHandlers?.requestAShow}
            bgColor={buttonBgColor}
            textColor={buttonTextColor}
          >
            {t("requestAShowCTA")}
          </LinkButton>
        </div>
      </Inner>
    </Card>
  )
}

export default RequestAShow
