import {
  FONT_FAMILY_PRIMARY,
  FONT_SIZE_PRIMARY,
  FONT_WEIGHT_PRIMARY,
  LINE_HEIGHT_PRIMARY,
} from "./variables"

export const responsive = {
  // mobile first, so mobile styles will apply to everything
  mobile: (css: string) => `
    @media only screen and (min-width: 0px) {
      ${css}
    }
  `,
  tablet: (css: string) => `
    @media only screen and (min-width: 500px) {
      ${css}
    }
  `,
  desktop: (css: string) => `
    @media only screen and (min-width: 699px) {
      ${css}
    }
  `,
}

export const font = ({
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  textTransform,
  letterSpacing,
}: {
  fontFamily?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  textTransform?: string
  letterSpacing?: string
}) => `
  font-family: ${fontFamily || FONT_FAMILY_PRIMARY};
  font-size: ${fontSize || FONT_SIZE_PRIMARY};
  font-weight: ${fontWeight || FONT_WEIGHT_PRIMARY};
  line-height: ${lineHeight || LINE_HEIGHT_PRIMARY};
  text-transform: ${textTransform || "none"};
  letter-spacing: ${letterSpacing || "0.01em"};
  `
