import { useEffect, useState } from "react"
import axios from "axios"
import * as Sentry from "@sentry/react"

interface props {
  methods: {
    initAnalytics: (token: string) => void
  }
}

interface env {
  APP_HOSTS: {
    accounts?: string
    skweb?: string
    media?: string
  }
}

const useEnv = ({ methods }: props) => {
  const [env, setEnv] = useState<env>({ APP_HOSTS: {} })

  const fetchEnv = async () => {
    try {
      const { data } = await axios({
        url: "/env",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: undefined,
      })

      if (data?.GA_TRACKING_CODE) methods?.initAnalytics(data.GA_TRACKING_CODE)
      if (data?.APP_HOSTS) setEnv({ APP_HOSTS: data.APP_HOSTS })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchEnv()
  }, [])

  return { env }
}

export default useEnv
