import { useContext, useEffect } from "react"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { PropertyParams } from "../../types/customTypes"

import { LinkButton, ArtistImage, Card } from ".."
import { useTranslation } from "react-i18next"
import { replaceStringWithJSX, skwebPathLocale } from "../../helpers/Helpers"
import { useLinkParams } from "../../hooks"
import { ArtistName, Inner, TextWrap, LinkWrap } from "./styles.subscribe"

const Subscribe = ({ artistName }: { artistName: string }) => {
  const { linkWithParams } = useLinkParams()
  const { t } = useTranslation()
  const {
    theme: {
      colorTheme,
      styleParameters,
      artistId,
      language,
      buttonBgColor,
      buttonTextColor,
    },
    env: { APP_HOSTS },
    analytics,
  }: PropertyParams = useContext(PropertiesContext)

  const subscribeMessage = artistName
    ? replaceStringWithJSX({
        text: t("trackCTAText"),
        jsx: (
          <ArtistName style={styleParameters} colorTheme={colorTheme}>
            {artistName}
          </ArtistName>
        ),
        match: "__ARTIST_NAME__",
        regExp: /(__ARTIST_NAME__)/,
      })
    : ""

  const href = linkWithParams({
    href: `https://${APP_HOSTS["accounts"]}/artists/${artistId}/trackings/signup`,
    utmParams: {
      source: "11593",
      campaign: "track-artist",
      medium: "partner",
      content: artistId,
    },
    customParams: {
      source_product: "widget",
      success_url: `https://${APP_HOSTS["skweb"]}${skwebPathLocale(
        language,
        "/trackings/recommendations"
      )}`,
    },
    withLocale: "param",
  })

  // fire subscribe section viewed analytic
  useEffect(() => {
    analytics?.viewHandlers?.subscribeSection("")
  }, [])

  return (
    <Card styleType="cta">
      <Inner colorTheme={colorTheme} data-testid="subscribeWrapper">
        <TextWrap style={styleParameters}>
          <ArtistImage artistId={artistId} />
          <p>{subscribeMessage}</p>
        </TextWrap>
        <LinkWrap>
          <LinkButton
            href={href}
            onClick={analytics?.clickHandlers?.subscribe}
            styleType="primary"
            text={t("subscribe")}
            bgColor={buttonBgColor}
            textColor={buttonTextColor}
          />
        </LinkWrap>
      </Inner>
    </Card>
  )
}

export default Subscribe
