const ShareIcon = ({ color }: { color?: string }) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00781 11.6562C8.41406 11.6562 8.74219 11.3281 8.74219 10.9375V3.07812L8.67969 1.92188L9.16406 2.49219L10.25 3.65625C10.3828 3.79688 10.5625 3.86719 10.7422 3.86719C11.1016 3.86719 11.3984 3.60938 11.3984 3.24219C11.3984 3.04688 11.3203 2.90625 11.1875 2.77344L8.5625 0.25C8.375 0.0625 8.20312 0 8.00781 0C7.82031 0 7.64062 0.0625 7.45312 0.25L4.82812 2.77344C4.69531 2.90625 4.625 3.04688 4.625 3.24219C4.625 3.60938 4.91406 3.86719 5.27344 3.86719C5.45312 3.86719 5.64062 3.79688 5.76562 3.65625L6.85156 2.49219L7.33594 1.91406L7.28125 3.07812V10.9375C7.28125 11.3281 7.60938 11.6562 8.00781 11.6562ZM3.5 17.8984H12.5C14.1953 17.8984 15.0703 17.0234 15.0703 15.3516V7.67188C15.0703 6 14.1953 5.125 12.5 5.125H10.3906V6.67969H12.3984C13.1094 6.67969 13.5156 7.05469 13.5156 7.80469V15.2109C13.5156 15.9609 13.1094 16.3359 12.3984 16.3359H3.60156C2.88281 16.3359 2.48438 15.9609 2.48438 15.2109V7.80469C2.48438 7.05469 2.88281 6.67969 3.60156 6.67969H5.63281V5.125H3.5C1.8125 5.125 0.929688 5.99219 0.929688 7.67188V15.3516C0.929688 17.0234 1.8125 17.8984 3.5 17.8984Z"
      fill={color}
    />
  </svg>
)

export default ShareIcon
