import { useContext } from "react"

import { TourboxEvent, PropertyParams } from "../../types/customTypes"
import { PropertiesContext } from "../../context/PropertiesProvider"
import { Wrap, Venue, City } from "./styles.location"

const Location = ({ event }: { event: TourboxEvent }) => {
  const { location, venue, type, displayName } = event
  const {
    theme: { colorTheme, styleParameters },
  }: PropertyParams = useContext(PropertiesContext)
  switch (type) {
    case "Festival":
      return (
        <Wrap colorTheme={colorTheme} data-testid="festiralWrapper">
          {displayName && <City style={styleParameters}>{displayName}</City>}
          {location?.city && (
            <Venue style={styleParameters}>
              {venue?.displayName ? `${venue?.displayName}, ` : ""}
              {location?.city}
            </Venue>
          )}
        </Wrap>
      )
    default:
      return (
        <Wrap colorTheme={colorTheme} data-testid="locationWrapper">
          {location?.city && (
            <City style={styleParameters}>{location.city}</City>
          )}
          {venue?.displayName && (
            <Venue style={styleParameters}>{venue?.displayName}</Venue>
          )}
        </Wrap>
      )
  }
}

export default Location
