import styled from "styled-components"
import { font } from "../../../styles/mixins"

interface props {
  themeColor: string
}

//  ant design overrides
export const DropdownWrap = styled.div<props>`
  ${({ color, themeColor }) => `
  display: inline;
  .ant-select-single {
    .ant-select-selector {
      .ant-select-selection-item {
        color: ${color} !important;
        ${font({
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "38px",
        })}
        span {
          svg {
            fill: ${color} !important;
          }
        }
      }
    }
  }
  .ant-select-dropdown {
    .ant-select-selector {
      .ant-select-selection-item {
        color: ${themeColor} !important;
        span {
          svg {
            fill: ${themeColor} !important;
          }
        }
      }
    }
  }
`}
`
