import styled from "styled-components"

interface trayProps {
  background?: string
}

export const Wrap = styled.div`
  position: relative;
`

export const ShareTray = styled.div<trayProps>`
  ${({ background }) => `
    background-color: ${background};
    pointer-events: all;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 15px 8px;
    border-radius: 4px;
    position: absolute;
    right: -2px;
    top: -2px;
    z-index: 1;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12);
  `}
`
