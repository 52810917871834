import { useContext } from "react"
import { PropertiesContext } from "../../context/PropertiesProvider"

/* 

  Use this hook to handle params on links.
  Utm params and custom params are handled identically, with the exception that utm params are prefixed with 'utm_'.
  Current params on the link are maintained
  Pass withLocale to include the locale as "subDir" | "param"

 */
interface args {
  href: string
  utmParams?: {
    [k: string]: string
  }
  customParams?: {
    [k: string]: string
  }
  withLocale?: "subDir" | "param"
}

const useLinkParams = () => {
  const {
    theme: { language },
  } = useContext(PropertiesContext)

  const linkWithParams = ({
    href,
    utmParams,
    customParams,
    withLocale,
  }: args) => {
    const url = new URL(href)
    const params = new URLSearchParams(url.search)

    const addParams = (_params: args["customParams"], prefix: string) => {
      if (!_params) return null
      return Object.keys(_params).forEach((paramName) => {
        const value = _params[paramName as string]
        params.set(`${prefix}${paramName}`, value)
      })
    }

    const handleLocale = () => {
      if (language === "en") return null
      switch (withLocale) {
        case "subDir":
          url.pathname = `/${language}${url.pathname}`
          break
        case "param":
          params.set("locale", language)
          break
        default:
          return null
      }
    }

    addParams(customParams, "")
    addParams(utmParams, "utm_")
    handleLocale()

    return `${url.origin}${url.pathname}${
      params.toString().length ? "?" + params.toString() : ""
    }`
  }
  return { linkWithParams }
}
export default useLinkParams
