import { useContext } from "react"

import { RSVP, Share } from ".."
import { Wrap } from "./styles.eventIcons"
import { PropertiesContext } from "../../context/PropertiesProvider"

interface props {
  toggleOverlay: (isActive: boolean) => void
  eventLink: string
  eventId: number
}

const EventIcons = ({ toggleOverlay, eventLink, eventId }: props) => {
  const {
    theme: { showShareButton, showRSVP },
  } = useContext(PropertiesContext)

  if (!showShareButton && !showRSVP) return null

  return (
    <Wrap>
      <Share toggleOverlay={toggleOverlay} eventLink={eventLink} />
      <RSVP eventLink={eventLink} eventId={eventId} />
    </Wrap>
  )
}

export default EventIcons
