import styled from "styled-components"
import { responsive } from "../../styles/mixins"

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  ${responsive.tablet(`
    grid-template-columns: 1fr auto auto;
    grid-template-rows: 1
  `)}
`
interface props {
  mobileMarginBottom: string
}

export const PerformanceLink = styled.a<props>`
  margin-bottom: ${({ mobileMarginBottom }) => mobileMarginBottom};
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${responsive.tablet(`
    width: 100%;
    padding-right: 12px;
    margin-bottom: 0px;
`)}
`
