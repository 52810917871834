import { useContext, useState, useRef } from "react"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { Button, Link } from ".."
import { ShareTray, Wrap } from "./styles.share"

// icons
import ShareIcon from "../../assets/icons/ShareIcon"
import PaperClipIcon from "../../assets/icons/PaperClipIcon"
import FacebookIcon from "../../assets/icons/FacebookIcon"
import TwitterIcon from "../../assets/icons/TwitterIcon"
import CrossIcon from "../../assets/icons/CrossIcon"
import CheckmarkIcon from "../../assets/icons/CheckmarkIcon"
import { useExternalClick, useLinkParams } from "../../hooks"
import { colorThemes } from "../../styles/theme"
import { useTranslation } from "react-i18next"

interface props {
  toggleOverlay: (isActive: boolean) => void
  eventLink: string
}

const Share = ({ toggleOverlay, eventLink }: props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [hasCopied, setHasCopied] = useState(false)
  const {
    theme: { showShareButton, colorTheme, styleParameters, artistId },
    responsive: { isMobile },
    analytics,
  } = useContext(PropertiesContext)
  const { t } = useTranslation()
  const cardRef = useRef<HTMLDivElement>(null)
  const { linkWithParams } = useLinkParams()
  const primaryColor = colorThemes[colorTheme].primary
  const bgColor = colorThemes[colorTheme].shareBg

  const shareLink = linkWithParams({
    href: eventLink,
    utmParams: {
      source: "11593",
      campaign: "event-row-share",
      medium: "partner",
      content: artistId,
    },
  })

  const copyToClipboard = (e: React.SyntheticEvent, value: string) => {
    e.stopPropagation()
    navigator.clipboard.writeText(value)
    setHasCopied(true)
    analytics?.clickHandlers?.share("copy")
    setTimeout(() => setHasCopied(false), 1200)
  }

  const toggleTray = (_isOpen: boolean) => {
    toggleOverlay(_isOpen)
    setIsOpen(_isOpen)
  }

  useExternalClick({
    ref: cardRef,
    externalClickHandler: () => toggleTray(false),
  })

  const options = [
    {
      link: shareLink,
      icon: hasCopied ? (
        <CheckmarkIcon />
      ) : (
        <PaperClipIcon color={primaryColor} />
      ),
      text: t("copyLink"),
      action: "button",
      onClick: copyToClipboard,
    },
    {
      link: `http://www.facebook.com/share.php?u=${shareLink}`,
      icon: <FacebookIcon color={primaryColor} />,
      text: "Facebook",
      action: "link",
      onClick: () => analytics?.clickHandlers?.share("faceboook"),
    },
    {
      link: `https://twitter.com/share?url=${shareLink}`,
      icon: <TwitterIcon color={primaryColor} />,
      text: "Twitter",
      action: "link",
      onClick: () => analytics?.clickHandlers?.share("twitter"),
    },
  ]

  if (!showShareButton) return null

  const renderOptions = () =>
    options.map((opt, i) => {
      const { action, link, icon, text, onClick } = opt
      const last = i == options.length - 1
      switch (action) {
        case "button":
          return (
            <Button
              styleType="link"
              key={link}
              style={{
                marginRight: "12px",
              }}
              withIcon
              onClick={(e) => onClick(e, link)}
            >
              {icon}
              {!isMobile && (
                <span style={{ whiteSpace: "nowrap" }}>{text}</span>
              )}
            </Button>
          )
        default:
          // link
          return (
            <Link
              key={link}
              href={link}
              target="_blank"
              styleType="link"
              onClick={(e) => onClick(e, "")}
              style={{ marginRight: !last ? "12px" : "" }}
              withIcon
            >
              {icon}
              {!isMobile && text}
            </Link>
          )
      }
    })

  const renderShareTray = () => (
    <ShareTray background={bgColor}>
      {renderOptions()}
      <Button
        style={{ marginLeft: "12px" }}
        onClick={() => toggleTray(false)}
        styleType="icon"
      >
        <CrossIcon
          color={colorThemes[colorTheme].primary}
          style={{ height: "10px", width: "10px" }}
        />
      </Button>
    </ShareTray>
  )

  return (
    <Wrap ref={cardRef}>
      <Button
        styleType="framedIcon"
        style={{
          alignSelf: isMobile ? "flex-start" : "unset",
        }}
        onClick={() => toggleTray(true)}
      >
        <ShareIcon
          color={styleParameters?.color || colorThemes[colorTheme].primary}
        />
      </Button>
      {isOpen && renderShareTray()}
    </Wrap>
  )
}

export default Share
