import { useState, useEffect, useContext, useMemo } from "react"
import axios from "axios"
import * as Sentry from "@sentry/react"

import {
  CalendarRes,
  Performance,
  CalendarResArtist,
} from "../../types/customTypes"
import { createFilterOptions } from "../../helpers/Helpers"
import { PropertiesContext } from "../../context/PropertiesProvider"
import { PropertyParams } from "../../types/customTypes"
import { useTranslation } from "react-i18next"

interface locationFilterOptions {
  value: string
  label: string
  count?: number | undefined
}

const useCalendar = () => {
  const [calendar, setCalendar] = useState([] as Performance[])
  const [filteredCalendar, setFilteredCalendar] = useState([] as Performance[])
  const [location, setLocation] = useState("")
  const [calendarType, setCalendarType] = useState("upcoming")
  const [locationFilterOptions, setLocationOptionsFilter] = useState<
    locationFilterOptions[]
  >([])
  const [artist, setArtist] = useState({} as CalendarResArtist)
  const [hasRequested, setHasRequested] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [calendarError, setCalendarError] = useState("")
  const {
    analytics,
    calendar: { setIsPast, isOnTour, setIsOnTour },
    theme: { artistId, showPastEventsIfOffTour },
  }: PropertyParams = useContext(PropertiesContext)

  const { t } = useTranslation()

  const calendarTypeOptions = [
    { value: "upcoming", label: t("upcomingEvents") },
    { value: "past", label: t("pastEvents") },
  ]

  // create filter options
  useMemo(() => {
    const locationFrequency = createFilterOptions(calendar)
    if (locationFrequency.length) {
      return setLocationOptionsFilter([
        { label: t("allLocations"), value: "" },
        ...locationFrequency,
      ])
    }
  }, [calendar])

  // filter calendar when location changes
  useMemo(() => {
    let _calendar = [...calendar]
    if (location) {
      _calendar = _calendar.filter(({ event }) => {
        if (event.type === "LiveStreamConcert")
          return location === "Live stream"
        return event?.venue?.metroArea?.country?.displayName === location
      })
    }
    return setFilteredCalendar(_calendar)
  }, [calendar, location])

  useMemo(() => {
    if (!calendar.length && !isOnTour && showPastEventsIfOffTour)
      setCalendarType("past")
  }, [calendar, isOnTour, showPastEventsIfOffTour])

  useEffect(() => {
    const isPast = calendarType === "past"
    setIsPast(isPast)
    setLocation("")
  }, [calendarType])

  const setOffTour = () => {
    setIsOnTour(false)
    return setCalendar([])
  }

  const handleCalendarData = (data: CalendarRes) => {
    const {
      results: { performance },
      totalEntries,
      artist,
    } = data.resultsPage

    if (!performance && totalEntries === 0) {
      setArtist(artist)
      return setOffTour()
    }

    setArtist({
      id: performance[0]?.artist?.id,
      name: performance[0]?.artist?.displayName,
    })

    // remove cancelled events
    const _calendar = performance.filter(
      ({ event }) => event.status !== "cancelled"
    )
    // offtour if events cancelled
    if (!_calendar.length) return setOffTour()

    setIsRequesting(false)
    setFilteredCalendar(_calendar)
    return setCalendar(_calendar)
  }

  const fetchCalendar = async (
    calendarType: string,
    awaitSecondRequest: boolean
  ) => {
    setIsRequesting(true)
    const qs = calendarType === "upcoming" ? "" : "?past_performances=true"
    try {
      const { data } = await axios({
        url: `/api/calendar/${artistId}${qs}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: undefined,
      })

      handleCalendarData(data)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const message = err?.response?.data
      setCalendarError(message || "unknown")

      return setIsRequesting(false)
    }
    // do not set to false unless we are not expecting to req the past calendar as fallback
    if (!awaitSecondRequest) setIsRequesting(false)
    setHasRequested(true)
  }

  useEffect(() => {
    // prevent flashing of calendar if we are showing past events as fallback
    const awaitSecondRequest =
      calendarType === "upcoming" && showPastEventsIfOffTour
    fetchCalendar(calendarType, awaitSecondRequest)
  }, [calendarType])

  // call events viewed analytics on load
  useEffect(() => {
    if (!calendar?.length) return undefined
    const hasDirectLinks = calendar.some(
      ({ directTicketLink }) => !!directTicketLink
    )
    analytics?.viewHandlers?.events(
      hasDirectLinks ? "any_direct_links" : "no_direct_links"
    )
  }, [calendar])

  return {
    calendar,
    filteredCalendar,
    artist,
    hasRequested,
    isRequesting,
    calendarError,
    setLocation,
    locationFilterOptions,
    location,
    calendarType,
    setCalendarType,
    calendarTypeOptions,
  }
}

export default useCalendar
