import { useEffect, useState } from "react"
import WebFont from "webfontloader"

export const fontCheck = new Set(
  [
    // Windows 10
    "Arial",
    "Arial Black",
    "Bahnschrift",
    "Calibri",
    "Cambria",
    "Cambria Math",
    "Candara",
    "Comic Sans MS",
    "Consolas",
    "Constantia",
    "Corbel",
    "Courier New",
    "Ebrima",
    "Franklin Gothic Medium",
    "Gabriola",
    "Gadugi",
    "Georgia",
    "HoloLens MDL2 Assets",
    "Impact",
    "Ink Free",
    "Javanese Text",
    "Leelawadee UI",
    "Lucida Console",
    "Lucida Sans Unicode",
    "Malgun Gothic",
    "Marlett",
    "Microsoft Himalaya",
    "Microsoft JhengHei",
    "Microsoft New Tai Lue",
    "Microsoft PhagsPa",
    "Microsoft Sans Serif",
    "Microsoft Tai Le",
    "Microsoft YaHei",
    "Microsoft Yi Baiti",
    "MingLiU-ExtB",
    "Mongolian Baiti",
    "MS Gothic",
    "MV Boli",
    "Myanmar Text",
    "Nirmala UI",
    "Palatino Linotype",
    "Segoe MDL2 Assets",
    "Segoe Print",
    "Segoe Script",
    "Segoe UI",
    "Segoe UI Historic",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "SimSun",
    "Sitka",
    "Sylfaen",
    "Symbol",
    "Tahoma",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
    "Webdings",
    "Wingdings",
    "Yu Gothic",
    // macOS
    "American Typewriter",
    "Andale Mono",
    "Arial",
    "Arial Black",
    "Arial Narrow",
    "Arial Rounded MT Bold",
    "Arial Unicode MS",
    "Avenir",
    "Avenir Next",
    "Avenir Next Condensed",
    "Baskerville",
    "Big Caslon",
    "Bodoni 72",
    "Bodoni 72 Oldstyle",
    "Bodoni 72 Smallcaps",
    "Bradley Hand",
    "Brush Script MT",
    "Chalkboard",
    "Chalkboard SE",
    "Chalkduster",
    "Charter",
    "Cochin",
    "Comic Sans MS",
    "Copperplate",
    "Courier",
    "Courier New",
    "Didot",
    "DIN Alternate",
    "DIN Condensed",
    "Futura",
    "Geneva",
    "Georgia",
    "Gill Sans",
    "Helvetica",
    "Helvetica Neue",
    "Herculanum",
    "Hoefler Text",
    "Impact",
    "Lucida Grande",
    "Luminari",
    "Marker Felt",
    "Menlo",
    "Microsoft Sans Serif",
    "Monaco",
    "Noteworthy",
    "Optima",
    "Palatino",
    "Papyrus",
    "Phosphate",
    "Rockwell",
    "Savoye LET",
    "SignPainter",
    "Skia",
    "Snell Roundhand",
    "Tahoma",
    "Times",
    "Times New Roman",
    "Trattatello",
    "Trebuchet MS",
    "Verdana",
    "Zapfino",
  ].sort()
)

const fontStyles = ["serif", "sans-serif", "cursive", "fantasy", "monospace"]

const useFontFamily = ({
  fontFamily: fontFamilyName,
}: {
  fontFamily: string | undefined
}) => {
  const [fontLoaded, setFontLoaded] = useState(false)

  // get fonts available to browser
  const getAvailableFonts = async () => {
    await document.fonts.ready
    const fontAvailable = new Set()

    for (const font of fontCheck.values()) {
      if (document.fonts.check(`12px "${font}"`)) {
        fontAvailable.add(font)
      }
    }

    return [...fontAvailable.values()]
  }

  // attempt font fetch from google
  const fetchFont = async (fontName: string) => {
    try {
      const promise = new Promise((resolve, reject) => {
        WebFont.load({
          google: { families: [fontName] },
          active: () => {
            resolve("Font loaded")
          },
          inactive: () => reject(new Error("404")),
        })
      })

      await promise

      setFontLoaded(true)
    } catch (err) {
      console.log("err", err)

      // font not available. Keep loaded font logic
    }
  }

  const detectFontAvailable = (
    requestedFonts: string[],
    availableFonts: unknown[]
  ) => {
    let fallbackFound = false
    requestedFonts.forEach((font) => {
      if (availableFonts.includes(font) || fontStyles.includes(font))
        fallbackFound = true
    })
    return fallbackFound
  }

  // remove inverted commas and spaces from font names
  const cleanFonts = (_fontNames: string) => {
    const cleanedFonts = _fontNames
      .split(",")
      .map((font) => font.replace(/['"]/g, "").trim())
    return { primaryFont: cleanedFonts[0], fontArr: cleanedFonts }
  }

  const loadFont = async (_fontNames: string) => {
    const { primaryFont, fontArr } = cleanFonts(_fontNames)
    const availableFonts = await getAvailableFonts()
    // detect if first font available
    let hasFont = detectFontAvailable([primaryFont], availableFonts)
    // attempt to fetch from google
    if (!hasFont) fetchFont(primaryFont)
    // // font still not loaded. fallback provided, check availability
    if (!hasFont && fontArr.length > 1)
      hasFont = detectFontAvailable(fontArr, availableFonts)

    return setFontLoaded(hasFont)
  }

  useEffect(() => {
    if (fontFamilyName) loadFont(fontFamilyName)
  }, [fontFamilyName])

  // if no requested font available. Return undefined, which will render our default font

  return {
    fontFamily: fontLoaded ? fontFamilyName : undefined,
    loadFont,
    detectFontAvailable,
    fetchFont,
    getAvailableFonts,
    setFontLoaded,
    fontLoaded,
  }
}

export default useFontFamily
