import React, { FC, useRef } from "react"
import styled from "styled-components"

import { useCalendar, useResponsive } from "../../hooks"
import {
  Header,
  Subscribe,
  CalendarFilter,
  RequestAShow,
  MoreEventsLink,
} from "../../components"
import { PerformanceItemList } from "../../components/PerformanceItemList/PerformanceItemList"
import { PropertiesContext } from "../../context/PropertiesProvider"
import { PropertyParams } from "../../types/customTypes"

interface props {
  colorTheme: "dark" | "light"
}

const Wrap = styled.div<props>`
  max-width: "1200px";
  margin: "0 auto";
  padding: "50px 0px 150px";
`

const Home: FC = () => {
  const appRef = useRef<HTMLDivElement>(null)
  useResponsive(appRef)
  const {
    theme: {
      showTrackButton,
      showCountryFilter,
      styleParameters,
      backgroundColor,
      colorTheme,
      showPastEvents,
      showPastEventsIfOffTour,
    },
    analytics,
    calendar: { isPast, isOnTour },
  }: PropertyParams = React.useContext(PropertiesContext)

  const {
    calendar,
    filteredCalendar,
    artist,
    hasRequested,
    calendarError,
    setLocation,
    locationFilterOptions,
    location,
    setCalendarType,
    calendarTypeOptions,
    calendarType,
    isRequesting,
  } = useCalendar()

  const calendarReady = hasRequested && !isRequesting
  const subscribeSection = calendar && showTrackButton
  const pastEventsSection = calendarReady && showPastEvents
  const countryFilterSection =
    calendarReady &&
    showCountryFilter &&
    !!locationFilterOptions.length &&
    !isPast
  const requestAShowSection =
    calendarReady && (!isPast || (isPast && !isOnTour))
  const moreEventsSection = calendarReady && isPast

  return (
    <Wrap ref={appRef} colorTheme={colorTheme}>
      <div
        style={{
          ...styleParameters,
          backgroundColor,
          paddingBottom: "16px",
          maxWidth: 1000,
          margin: "0 auto",
        }}
      >
        <Header />
        {subscribeSection && <Subscribe artistName={artist?.name} />}
        {pastEventsSection && (
          <CalendarFilter
            value={calendarType}
            options={calendarTypeOptions}
            onChangeDropdown={setCalendarType}
            hide={!isOnTour && showPastEventsIfOffTour}
          />
        )}
        {countryFilterSection && (
          <CalendarFilter
            value={location}
            options={locationFilterOptions}
            onChangeDropdown={(location: string) => setLocation(location)}
            analyticsClick={analytics?.clickHandlers?.countryFilterOption}
            analyticsView={analytics?.viewHandlers?.countryFilter}
          />
        )}
        <PerformanceItemList
          calendar={filteredCalendar}
          isRequesting={isRequesting}
          calendarError={calendarError}
        />
        {requestAShowSection && (
          <RequestAShow hasEvents={!!calendar.length} artist={artist} />
        )}
        {moreEventsSection && <MoreEventsLink />}
      </div>
    </Wrap>
  )
}

export default Home
