import styled from "styled-components"
import { rgba } from "polished"

import { colorThemes } from "../../styles/theme"
import { BLACK, TRANSITION_TIME } from "../../styles/variables"

interface props {
  colorTheme: "dark" | "light"
  overlayActive?: boolean
  styleType: "primary" | "cta" | "info"
}

export const CardItem = styled.div<props>`
  ${({ colorTheme, overlayActive, styleType }) => `
  padding: ${styleType === "primary" ? "20px 20px 24px" : "16px 16px 20px"} ;
  position: relative;
  background-color: ${colorThemes[colorTheme].cards[styleType]};
  border-radius: 8px;
  ${
    !overlayActive &&
    `
    &:hover {
      background-color: ${colorThemes[colorTheme].cards[`${styleType}Hover`]};
    }
    transition: background-color ${TRANSITION_TIME};
  `
  }

`}
`

export const Overlay = styled.div<props>`
  ${({ overlayActive, colorTheme, styleType }) => `
  border-radius: 8px;
  margin: ${styleType === "primary" ? "0 16px 12px 16px" : "0 16px 16px 16px"};
  ${
    overlayActive &&
    `
    background-color: ${
      colorTheme === "dark" ? rgba(BLACK, 0.4) : rgba(BLACK, 0.1)
    };
    pointer-events: none;
    `
  }
  transition: background-color ${TRANSITION_TIME} ease-in-out;
`}
`
