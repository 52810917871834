import { useContext } from "react"
import { useRouteError, isRouteErrorResponse } from "react-router-dom"
import styled from "styled-components"
import { PropertiesContext } from "../../context/PropertiesProvider"
import { colorThemes } from "../../styles/theme"
import { PropertyParams } from "../../types/customTypes"

interface props {
  colorTheme: "dark" | "light"
}

const Wrap = styled.div<props>`
  background-color: ${({ colorTheme }) => colorThemes[colorTheme].cards.cta};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
const H1 = styled.h1<props>`
  color: ${({ colorTheme }) => colorThemes[colorTheme].primary};
`

export default function ErrorPage() {
  const error = useRouteError()
  const {
    theme: { colorTheme },
  }: PropertyParams = useContext(PropertiesContext)
  if (isRouteErrorResponse(error)) {
    return (
      <Wrap colorTheme={colorTheme}>
        <H1 colorTheme={colorTheme}>Not Found</H1>
        <h2>{error.status}</h2>
        <p>{error.statusText}</p>
        {error.data?.message && <p>{error.data.message}</p>}
      </Wrap>
    )
  } else {
    return (
      <Wrap colorTheme={colorTheme}>
        <H1 colorTheme={colorTheme}>Oops</H1>
      </Wrap>
    )
  }
}
