import { useContext, ReactNode } from "react"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { PropertyParams } from "../../types/customTypes"
import { Overlay, CardItem } from "./styles.card"

interface props {
  overlayActive?: boolean
  styleType: "primary" | "cta" | "info"
  children: ReactNode | ReactNode[]
}

const Card = ({ overlayActive, children, styleType }: props) => {
  const {
    theme: { colorTheme },
  }: PropertyParams = useContext(PropertiesContext)

  return (
    <Overlay
      overlayActive={overlayActive}
      colorTheme={colorTheme}
      styleType={styleType}
    >
      <CardItem
        colorTheme={colorTheme}
        overlayActive={overlayActive}
        styleType={styleType}
        data-testid="performanceCard"
      >
        {children}
      </CardItem>
    </Overlay>
  )
}

export default Card
