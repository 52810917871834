import { Fragment } from "react"
import { Performance } from "../types/customTypes"

export const camelize = (str: string) => {
  if (!str) return ""
  if (!/[_-]/.test(str)) return str
  return str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (match) =>
      match.toUpperCase().replace("-", "").replace("_", "")
    )
}

export const skwebPathLocale = (_locale: string, path: string) => {
  const locale = _locale !== "en" ? `/${_locale}` : ""
  return `${locale}${path}`
}

// take a string and replace a given part of it with given jsx
export const replaceStringWithJSX = ({
  text,
  jsx,
  match,
  regExp,
}: {
  text: string
  jsx: JSX.Element
  match: string
  regExp: RegExp
}) => {
  return text.split(regExp).map((part: string, i: number) => {
    if (part === match) return <Fragment key={i}>{jsx}</Fragment>
    return <Fragment key={i}>{part}</Fragment>
  })
}

export const createFilterOptions = (calendar: Performance[]) => {
  const locations = calendar.map(({ event }) => {
    if (event.type === "LiveStreamConcert") return "Live stream"
    return event.venue?.metroArea?.country?.displayName
  })

  const locationCount = new Map()

  locations.forEach((location) => {
    if (locationCount.has(location)) {
      locationCount.set(location, locationCount.get(location) + 1)
    } else {
      locationCount.set(location, 1)
    }
  })

  const locationList = Array.from(locationCount).map(([value, count]) => ({
    value,
    label: `${value} (${count})`,
    count,
  }))

  return locationList.sort(function (a, b) {
    const nameA = a.label.toLowerCase()
    const nameB = b.label.toLowerCase()
    if (nameA < nameB)
      //sort string ascending
      return -1
    if (nameA > nameB) return 1
    return 0 //default return value (no sorting)
  })
}
