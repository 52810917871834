import styled from "styled-components"

import { responsive, font } from "../../styles/mixins"

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 16px;
  ${font({
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
  })}
  ${responsive.tablet(`
    margin-left: 40px;
    grid-column-start: 3;
    justify-content: center;
    align-items: center;
  `)}
`
