import {
  DetailedHTMLProps,
  HTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from "react"
import i18next from "i18next"
import { useFontFamily } from ".."
import {
  QueryParams,
  ReactStyleProperties,
  ReactStyleObject,
} from "../../types/customTypes"
import { camelize } from "../../helpers/Helpers"

const allowedThemes = ["light", "dark"]

const useParams = ({ searchParams }: { searchParams: URLSearchParams }) => {
  const [language, setLanguage] = useState("en")
  const queryParams = useMemo(() => {
    const params = {} as QueryParams
    for (const [key, value] of searchParams.entries()) {
      if (key === "font-color") params.color = value
      else params[key as keyof QueryParams] = value
    }
    return params
  }, [searchParams])

  const artistId = queryParams["artist"]
  const header = queryParams.header
    ? decodeURIComponent(queryParams.header)
    : ""
  const locale = queryParams.locale || "en"
  const isStaging = queryParams.staging == "on"

  const theme: QueryParams["theme"] =
    queryParams.theme && allowedThemes.includes(queryParams.theme)
      ? queryParams.theme
      : "dark"
  // default false for show other artists
  const showOtherArtists = queryParams["other-artists"]
    ? queryParams["other-artists"] === "on"
    : false
  // default false for show subscribe button
  const showTrackButton = queryParams["track-button"]
    ? queryParams["track-button"] === "on"
    : false
  // default true for share button
  const showShareButton = queryParams["share-button"]
    ? queryParams["share-button"] === "on"
    : true
  // default true for country filter
  const showCountryFilter = queryParams["country-filter"]
    ? queryParams["country-filter"] === "on"
    : true
  // default false for rsvp
  const showRSVP = queryParams["rsvp"] ? queryParams["rsvp"] === "on" : true
  // default false for request a show
  const showRequestEvent = queryParams["request-show"]
    ? queryParams["request-show"] === "on"
    : true
  // default false for remind me
  const showRemindMe = queryParams["remind-me"]
    ? queryParams["remind-me"] === "on"
    : false
  // default false for past events
  const showPastEvents = queryParams["past-events"]
    ? queryParams["past-events"] === "on"
    : false
  // default false for past events off tour
  const showPastEventsIfOffTour =
    showPastEvents && queryParams["past-events-offtour"]
      ? queryParams["past-events-offtour"] === "on"
      : false
  const itemsPerPage = 10
  const backgroundColor = queryParams["background-color"]
  const buttonTextColor = (queryParams["button-text-color"] || undefined) as
    | string
    | undefined
  const buttonBgColor = (queryParams["button-bg-color"] || undefined) as
    | string
    | undefined
  const nonStyleFields = [
    "artist",
    "header",
    "locale",
    "staging",
    "theme",
    "background-color",
    "button-text-color",
    "button-bg-color",
    "font-size",
    "other-artists",
    "track-button",
    "share-button",
    "country-filter",
    "rsvp",
  ]

  Object.keys(queryParams).forEach((key) => {
    if (nonStyleFields.includes(key)) delete queryParams[key]
  })

  // style params must be camel case for react
  const styleParameters = Object.keys(queryParams).reduce((styles, key) => {
    const val = queryParams[key] as string
    const camelKey = camelize(key) as ReactStyleProperties
    if (camelKey) {
      styles[camelKey] = val
    }
    return styles
  }, {} as ReactStyleObject)

  const { fontFamily } = useFontFamily({
    fontFamily: queryParams["font-family"],
  })
  styleParameters.fontFamily = fontFamily

  // set language
  useEffect(() => {
    if (locale !== language) {
      setLanguage(locale)
      i18next.changeLanguage(locale)
    }
  }, [locale, language])

  return {
    styleParameters: styleParameters as DetailedHTMLProps<
      HTMLAttributes<HTMLElement>,
      HTMLElement
    >,
    artistId,
    header,
    language,
    isStaging,
    colorTheme: theme,
    showOtherArtists,
    showTrackButton,
    showShareButton,
    color: queryParams.color as string,
    fontFamily,
    itemsPerPage,
    backgroundColor,
    buttonTextColor,
    buttonBgColor,
    showCountryFilter,
    showRSVP,
    showRequestEvent,
    showRemindMe,
    showPastEvents,
    showPastEventsIfOffTour,
  }
}
export default useParams
