import { ForwardedRef } from "react"
import styled from "styled-components"

import { responsive, font } from "../../../styles/mixins"
import { colorThemes } from "../../../styles/theme"
import { props } from "./Button"

interface btnProps extends props {
  colorTheme: "dark" | "light"
  ref?: ForwardedRef<HTMLButtonElement>
  bgColor: string | undefined
}

export const Btn = styled.button<btnProps>`
  ${({ styleType, bgColor, colorTheme, withIcon }) => `
  ${colorThemes[colorTheme].buttons[styleType]({ bgColor })}
  ${font({
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 400,
  })}
  ${
    withIcon
      ? responsive.tablet(
          `
          svg {
            margin-right: 12px;
          }
          `
        )
      : ""
  }
`}
`
