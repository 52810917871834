import { RouterProvider, createBrowserRouter } from "react-router-dom"
import ErrorPage from "../pages/ErrorPage/ErrorPage"
import { PropertiesProvider } from "../context/PropertiesProvider"
import Home from "../pages/Home/Home"

const Router = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
  ])

  return (
    <PropertiesProvider>
      <RouterProvider router={router} />
    </PropertiesProvider>
  )
}

export default Router
