import { RefObject, useEffect, useContext } from "react"
import { PropertiesContext } from "../../context/PropertiesProvider"
import { PropertyParams } from "../../types/customTypes"

let timer: ReturnType<typeof setTimeout>

const useResponsive = (ref: RefObject<HTMLElement>) => {
  const {
    parentMethods,
    responsive: { isMobile, setIsMobile },
  }: PropertyParams = useContext(PropertiesContext)

  const setHeight = (appHeight: number) => {
    if (parentMethods?.setIframeHeight) {
      parentMethods.setIframeHeight(appHeight)
    }
  }

  const setWidth = (width: number) => {
    if (width < 500 && !isMobile) {
      setIsMobile(true)
    } else if (width > 500 && isMobile) {
      setIsMobile(false)
    }
  }

  const onResizeEnd = ({
    width,
    height,
  }: {
    width: number
    height: number
  }) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      setHeight(height)
      setWidth(width)
    }, 250)
  }

  const observer = new ResizeObserver((entries) => {
    const { width, height } = entries[0].contentRect
    onResizeEnd({ width, height })
  })

  const setHeightOnly = ({ clientHeight }: HTMLElement) => {
    setHeight(clientHeight)
  }

  useEffect(() => {
    // turn off for incompatible browsers
    if (typeof ResizeObserver === "undefined") {
      if (ref.current) setHeightOnly(ref.current)
      else return undefined
    }
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      if (ref.current) observer.unobserve(ref.current)
    }
  }, [ref, isMobile, parentMethods?.setIframeHeight])

  return { setHeight }
}

export default useResponsive
