import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import translationEN from "./en/translation.json"
import translationFR from "./fr/translation.json"
import translationES from "./es/translation.json"
import translationDE from "./de/translation.json"
import translationPT from "./pt/translation.json"

export const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES,
  },
  de: {
    translation: translationDE,
  },
  pt: {
    translation: translationPT,
  },
}

// language detector
i18next.use(initReactI18next).init({
  lng: "en", // if you're using a language detector, do not define the lng option
  resources,
})
