import { useEffect } from "react"

import { DropDown } from ".."

interface OptionType {
  value: string
  label: string
}

interface Props {
  value?: string | number
  options?: OptionType[]
  onChangeDropdown?: (location: string) => void
  analyticsClick?: (label: string) => void
  analyticsView?: (label: string) => void
  isDisabled?: boolean
  hide?: boolean
}

const CalendarFilter = ({
  value,
  options,
  onChangeDropdown,
  analyticsClick,
  analyticsView,
  isDisabled,
  hide,
}: Props) => {
  const handleDropdownItemSelected = (selected: string | number) => {
    if (analyticsClick) analyticsClick("")
    if (onChangeDropdown) onChangeDropdown(`${selected}`)
  }

  useEffect(() => {
    if (analyticsView) analyticsView("")
  }, [])

  if (hide) return null

  return (
    <DropDown
      style={{ margin: "0 0 16px 16px" }}
      value={value}
      options={options}
      onChangeDropdown={(location: string | number) =>
        handleDropdownItemSelected(location)
      }
      isDisabled={isDisabled}
    />
  )
}

export default CalendarFilter
