import { useContext } from "react"
import styled from "styled-components"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { Performance, PropertyParams } from "../../types/customTypes"
import { colorThemes } from "../../styles/theme"
import { LinkButton, RemindMe } from ".."
import { useTranslation } from "react-i18next"
import { useLinkParams } from "../../hooks"
import { responsive } from "../../styles/mixins"
interface wrapProps {
  colorTheme: "dark" | "light"
}

const Wrap = styled.div<wrapProps>`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ colorTheme }) => colorThemes[colorTheme].primary};
  ${responsive.tablet(`
    grid-row: 1;
    grid-column: 2 / 3;
`)}
`

const TicketLinks = ({ performance }: { performance: Performance }) => {
  const {
    directTicketLabel,
    additionalUrl,
    additionalLabel,
    directTicketLink,
    event,
  } = performance

  const {
    theme: { colorTheme, artistId, buttonTextColor, buttonBgColor },
    analytics,
  }: PropertyParams = useContext(PropertiesContext)
  const { t } = useTranslation()
  const { linkWithParams } = useLinkParams()

  const eventHref = (linkType: string) =>
    linkWithParams({
      href: event.uri,
      utmParams: {
        campaign: linkType,
        content: artistId,
        source: "11593",
        medium: "partner",
      },
      withLocale: "subDir",
    })

  const mainUrl = directTicketLink || eventHref("ticket-link")
  const secondaryUrl = additionalUrl || eventHref("secondary-ticket-link")
  const isPostponed = event.status === "postponed"
  const mainLinkText = directTicketLabel || t("ticketLabel") || "Tickets"

  return (
    <Wrap colorTheme={colorTheme} data-testid="ticketLinksWrapper">
      <RemindMe
        performance={performance}
        skCalendarLink={eventHref("ticket-link")}
      />
      <LinkButton
        styleType="primary"
        analyticsLabel={directTicketLink || event.id}
        analyticsAction={directTicketLink ? "click_direct" : "click_skweb"}
        href={mainUrl}
        text={isPostponed ? t("postponed") : mainLinkText}
        onClick={analytics?.clickHandlers?.tickets}
        color={buttonTextColor}
        bgColor={buttonBgColor}
        textColor={buttonTextColor}
      />
      {additionalLabel && !isPostponed && (
        <LinkButton
          styleType="secondary"
          analyticsLabel={secondaryUrl}
          analyticsAction={"click_additional_url"}
          href={secondaryUrl}
          text={additionalLabel}
          onClick={analytics?.clickHandlers?.tickets}
          color={buttonTextColor}
          bgColor={buttonBgColor}
          textColor={buttonTextColor}
        />
      )}
    </Wrap>
  )
}

export default TicketLinks
