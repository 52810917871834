import styled from "styled-components"

import { font } from "../../styles/mixins"
import { colorThemes } from "../../styles/theme"
import { responsive } from "../../styles/mixins"

interface props {
  colorTheme: "dark" | "light"
}

export const Inner = styled.div<props>`
  color: ${({ colorTheme }) => colorThemes[colorTheme].primary};
  ${font({
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
  })}
  display: flex;
  flex-direction: column;
  ${responsive.tablet(`
    flex-direction: row;
    justify-content: space-between;
  `)}
`
export const TextWrap = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  ${responsive.tablet(`
    padding-right: 16px;
    margin-bottom: 0;
  `)}
`

export const LinkWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ArtistName = styled.span<props>`
  ${font({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
  })}
`
