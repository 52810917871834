const HeartIcon = ({
  color,
  style,
}: {
  color: string
  style?: React.CSSProperties
}) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M14.6668 5.46626C14.6668 6.46861 14.271 7.43137 13.564 8.14355C11.9368 9.78343 10.3584 11.4934 8.67036 13.0739C8.28343 13.4309 7.66963 13.4179 7.29936 13.0447L2.436 8.14355C0.965994 6.66208 0.965994 4.27043 2.436 2.78898C3.92045 1.29298 6.33879 1.29298 7.82323 2.78898L8.00003 2.96713L8.17669 2.78909C8.88843 2.07144 9.85776 1.66667 10.8704 1.66667C11.883 1.66667 12.8522 2.0714 13.564 2.78898C14.271 3.50122 14.6668 4.46393 14.6668 5.46626Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
)

export default HeartIcon
