import { useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { colorThemes } from "../../styles/theme"
import { font } from "../../styles/mixins"
import { PropertyParams } from "../../types/customTypes"
import { Card } from ".."

interface cardProps {
  colorTheme: "dark" | "light"
}

const Inner = styled.div<cardProps>`
  padding: 8px 0 4px;
  color: ${({ colorTheme }) => colorThemes[colorTheme].primary};
  ${font({
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
  })}
`

const NoEventsMessage = ({ calendarError }: { calendarError: string }) => {
  const { t } = useTranslation()
  const {
    theme: { colorTheme, styleParameters },
  }: PropertyParams = useContext(PropertiesContext)

  const renderError = () => {
    if (calendarError === "No Artist ID provided")
      return <p>Please provide an artist ID</p>

    if (calendarError) return <p>{t("noResponse")}</p>
    // empty response, no error
    return <p>{t("noEvents")}</p>
  }

  return (
    <Card styleType="info">
      <Inner colorTheme={colorTheme} style={styleParameters}>
        {renderError()}
      </Inner>
    </Card>
  )
}

export default NoEventsMessage
