import { useContext } from "react"
import styled from "styled-components"
import { PropertiesContext } from "../../context/PropertiesProvider"
import { colorThemes } from "../../styles/theme"
import { TourboxEvent, PropertyParams } from "../../types/customTypes"
import { font } from "../../styles/mixins"
interface props {
  colorTheme: "dark" | "light"
}

const EventDisplayName = styled.p<props>`
  color: ${({ colorTheme }) => colorThemes[colorTheme].primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${font({
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "27px",
    textTransform: "sentencecase",
  })}
`
const EventName = ({
  displayName,
  style,
}: {
  displayName: TourboxEvent["displayName"]
  style?: React.CSSProperties
}) => {
  const {
    theme: { colorTheme, styleParameters },
  }: PropertyParams = useContext(PropertiesContext)

  return displayName ? (
    <EventDisplayName
      colorTheme={colorTheme}
      style={{ ...styleParameters, ...style }}
    >
      {displayName}
    </EventDisplayName>
  ) : (
    <p></p>
  )
}
export default EventName
