const TwitterIcon = ({ color }: { color?: string }) => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 1.00914C21 1.00914 19.1655 2.09288 18.1455 2.40005C17.5979 1.77052 16.8703 1.32432 16.0609 1.1218C15.2515 0.91929 14.3995 0.970226 13.6201 1.26774C12.8406 1.56524 12.1713 2.09496 11.7027 2.78524C11.2341 3.47554 10.9888 4.29308 11 5.12733V6.03642C9.40236 6.07784 7.81934 5.72352 6.39183 5.005C4.96431 4.28648 3.73665 3.22608 2.81818 1.91824C2.81818 1.91824 -0.818182 10.1001 7.36364 13.7365C5.49139 15.0073 3.26105 15.6445 1 15.5546C9.18182 20.1001 19.1818 15.5546 19.1818 5.10005C19.181 4.84683 19.1566 4.59423 19.1091 4.34551C20.0369 3.4305 21 1.00914 21 1.00914Z"
      stroke={color}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default TwitterIcon
