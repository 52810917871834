import { useContext } from "react"
import styled from "styled-components"
import { PropertiesContext } from "../../context/PropertiesProvider"
import { useLinkParams } from "../../hooks"
import { skwebPathLocale } from "../../helpers/Helpers"
import LinkButton from "../Generic/LinkButton/LinkButton"

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

const MoreEventsLink = () => {
  const {
    theme: { language, artistId },
    env: { APP_HOSTS },
  } = useContext(PropertiesContext)
  const { linkWithParams } = useLinkParams()

  const href = linkWithParams({
    href: `https://${APP_HOSTS["skweb"]}${skwebPathLocale(
      language,
      `/artists/${artistId}/gigography`
    )}`,
  })

  return (
    <Wrap>
      <LinkButton href={href} styleType="primary" style={{ maxWidth: "200px" }}>
        See more events
      </LinkButton>
    </Wrap>
  )
}

export default MoreEventsLink
