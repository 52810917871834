import React from "react"
import ReactDOM from "react-dom/client"
import * as Sentry from "@sentry/react"
import axios from "axios"

import "./styles/global.scss"
import App from "./App/App"

// env var is not being detected correctly, using node env for now
// process.env.REACT_APP_SONGKICK_ENV

// sentry init
if (process.env.NODE_ENV === "production") {
  console.log("Initialize Sentry")
  Sentry.init({
    dsn: "https://9d7738fa6e4e39a5098bc86daaac6c44@o225327.ingest.sentry.io/4505714750521344",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: "production",
    enabled: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.05,

    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.05,
    beforeSend: function (event) {
      // filter out UnhandledRejection errors that have no information
      if (
        event !== undefined &&
        event.exception !== undefined &&
        event.exception.values !== undefined &&
        event.exception.values.length == 1
      ) {
        const e = event.exception.values[0]
        if (
          e.type === "UnhandledRejection" &&
          e.value?.match(
            /Non-Error promise rejection captured with value: Object Not Found Matching Id/
          )
        ) {
          return null
        }
      }
      return event
    },
  })
}

// axios error handling
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      Sentry.captureException(error.response.data)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      Sentry.captureException(error.message)
    }
    return Promise.reject(error)
  }
)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
