import { useContext } from "react"
import styled from "styled-components"

import { PropertiesContext } from "../../context/PropertiesProvider"
import { PropertyParams } from "../../types/customTypes"
import { colorThemes } from "../../styles/theme"
import { LinkButton } from ".."
import { responsive } from "../../styles/mixins"
interface wrapProps {
  colorTheme: "dark" | "light"
}

const Wrap = styled.div<wrapProps>`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ colorTheme }) => colorThemes[colorTheme].primary};
  ${responsive.tablet(`
  grid-area: 1 / 2 / auto / 3;
`)}
`

const LiveStreamLinks = ({ label, link }: { label: string; link: string }) => {
  const {
    theme: { colorTheme, buttonBgColor, buttonTextColor },
  }: PropertyParams = useContext(PropertiesContext)

  return (
    <Wrap colorTheme={colorTheme}>
      <LinkButton
        styleType="primary"
        href={link}
        text={label}
        bgColor={buttonBgColor}
        textColor={buttonTextColor}
      />
    </Wrap>
  )
}

export default LiveStreamLinks
