const CrossIcon = ({
  color,
  style,
}: {
  color: string
  style?: React.CSSProperties
}) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M10.6663 1.83331L1.33301 11.1666M10.6663 11.1666L1.33301 1.83331L10.6663 11.1666Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CrossIcon
