import { useEffect, RefObject } from "react"

interface props {
  ref: RefObject<HTMLElement>
  externalClickHandler: (e: MouseEvent) => void
}

const UseExternalClick = ({ ref, externalClickHandler }: props) => {
  const handleClickOutside = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      externalClickHandler(e)
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])
}

export default UseExternalClick
