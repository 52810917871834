import { useEffect, useState } from "react"
import { AsyncMethodReturns, connectToParent } from "penpal"

import { ParentAPI } from "../../types/customTypes"

interface props {
  methods: {
    setReferrer: (url: string) => void
  }
}

const useConnect = ({ methods }: props) => {
  const [parentMethods, setParentMethods] =
    useState<AsyncMethodReturns<ParentAPI> | null>(null)

  const connection = connectToParent<ParentAPI>({
    // Methods child is exposing to parent.
    // Add debug: true to see error logs
    methods: {
      setReferrer: (url: string) => methods?.setReferrer(url),
    },
  })

  // make connection
  useEffect(() => {
    connection.promise
      .then((clientMethods: AsyncMethodReturns<ParentAPI>) => {
        setParentMethods(clientMethods)
      })
      .catch((err) => {
        console.error("CONNECTION ERROR", err)
      })
  }, [])

  return parentMethods
}

export default useConnect
