import { useContext } from "react"
import styled from "styled-components"

import { responsive } from "../../../styles/mixins"
import { colorThemes } from "../../../styles/theme"
import { PropertiesContext } from "../../../context/PropertiesProvider"
import { PropertyParams } from "../../../types/customTypes"

interface props {
  styleType:
    | "primary"
    | "secondary"
    | "tertiary"
    | "framedIcon"
    | "icon"
    | "link"
  href: string
  target?: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  children?: React.ReactNode
  style?: React.CSSProperties
  withIcon?: boolean | undefined
}

interface linkProps extends props {
  colorTheme: "dark" | "light"
}

const Lnk = styled.a<linkProps>`
  ${({ styleType, colorTheme, withIcon }) => `
  ${colorThemes[colorTheme].buttons[styleType]({})}
  ${
    withIcon
      ? responsive.tablet(
          `
          svg {
            margin-right: 12px;
          }
          `
        )
      : ""
  }
`}
`

const Link = ({ children, onClick, withIcon, style, ...otherProps }: props) => {
  const {
    theme: { colorTheme },
  }: PropertyParams = useContext(PropertiesContext)

  return (
    <Lnk
      colorTheme={colorTheme}
      onClick={onClick}
      withIcon={withIcon}
      style={style}
      {...otherProps}
    >
      {children}
    </Lnk>
  )
}

export default Link
