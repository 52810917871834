import { SyntheticEvent, useContext } from "react"
import styled from "styled-components"

import { font, responsive } from "../../../styles/mixins"
import { colorThemes } from "../../../styles/theme"
import { PropertiesContext } from "../../../context/PropertiesProvider"
import { PropertyParams } from "../../../types/customTypes"

interface linkProps {
  styleType: "primary" | "secondary" | "tertiary" | "icon" | "framedIcon"
  colorTheme: "dark" | "light"
  bgColor: string | undefined
  textColor: string | undefined
}

const Link = styled.a<linkProps>`
  ${({ styleType, colorTheme, bgColor, textColor }) => `
  ${colorThemes[colorTheme].buttons[styleType]({ bgColor, textColor })}
  ${
    styleType !== "framedIcon" &&
    `
    width: 100%;
    padding: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    ${font({
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "21px",
    })}
    ${responsive.tablet(`
        width: 200px;
      `)}
    `
  } 
`}
`

export interface ButtonProps {
  onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void
  text?: string | null
  href: string
  styleType: "primary" | "secondary" | "tertiary" | "icon" | "framedIcon"
  analyticsLabel?: string | number
  analyticsAction?: string
  children?: React.ReactNode
  style?: React.CSSProperties
  color?: string | undefined
  bgColor?: string | undefined
  textColor?: string | undefined
}

const LinkButton = ({
  text,
  onClick,
  href,
  styleType,
  analyticsAction,
  analyticsLabel,
  children,
  style,
  bgColor,
  textColor,
}: ButtonProps) => {
  const {
    theme: { colorTheme, styleParameters },
  }: PropertyParams = useContext(PropertiesContext)

  // remove theme color from buttons
  const buttonStyles = { ...styleParameters }
  delete buttonStyles.color

  return (
    <Link
      data-testid="linkButton"
      data-id={analyticsLabel || ""}
      data-action={analyticsAction || ""}
      style={{
        ...buttonStyles,
        ...style,
      }}
      styleType={styleType}
      colorTheme={colorTheme}
      onClick={onClick}
      href={href}
      target="_blank"
      bgColor={bgColor}
      textColor={textColor}
    >
      {text}
      {children}
    </Link>
  )
}

export default LinkButton
