import styled from "styled-components"
import { font, responsive } from "../../styles/mixins"

const fontFace = font({
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: "10px",
  lineHeight: "15px",
  letterSpacing: "0.05em",
})

export const SaleDate = styled.span`
  ${fontFace}
  margin-bottom: 8px;
  border-radius: 4px;
`

interface props {
  colorTheme: "light" | "dark"
}

export const RemindMeCTA = styled.span<props>`
  ${fontFace}
  padding: 2px 45px;
  border-radius: 4px;
  ${responsive.tablet(`
      width: 100%;
      padding: 2px 5px;
    `)}
`
