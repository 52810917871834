/* COLORS */
export const BLACK = "#000"
export const WHITE = "#FFF"
export const CHARCOAL = "#0C0D0D"
export const ASH = "#F2F2F3"
export const OFF_BLACK = "#18191B"
export const WHITE20 = "rgba(255, 255, 255, 0.2)"

/* TYPOGRAPHY */
export const FONT_FAMILY_PRIMARY = "Inter"
export const FONT_SIZE_PRIMARY = "12px"
export const LINE_HEIGHT_PRIMARY = "18px"
export const FONT_WEIGHT_PRIMARY = 400

/* TRANSITIONS */
export const TRANSITION_TIME = "0.3s"
